import { useNavigate } from "react-router-dom";
import { useCallback, useMemo } from "react";

import useUserAgent from "core/user-agent/use-user-agent";
import {
  NavigationAction,
  ScreenType,
  sendMessageToNative,
} from "core/channels/bridge-handler";

interface StackNavigateOptions {
  type?: NavigationAction;
  replace?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state?: any;
  preventScrollReset?: boolean;
  params?: { [index: string]: unknown };
  screenType?: ScreenType;
}

const stackNavigte = (to: string | number, options?: StackNavigateOptions) => {
  sendMessageToNative("NATIVE_EVENT", {
    method: "navigate",
    params: {
      navigationAction: options?.type || "push",
      path: String(to),
      params: options?.params,
      screenType: options?.screenType || "webview",
    },
  });
};

const useStackNavigate = () => {
  const { appVersion } = useUserAgent();
  const reactRouterNavigate = useNavigate();

  const isStackSupportApp = useMemo(
    () => appVersion && Number(appVersion.split(".")[0]) >= 2,
    [appVersion]
  );

  const navigate = useCallback(
    (to: string | number, options?: StackNavigateOptions) => {
      if (isStackSupportApp) {
        if (to === -1) {
          stackNavigte(to, { type: "goBack", ...options });
        } else {
          stackNavigte(to, options);
        }
      } else {
        if (typeof to === "string") {
          reactRouterNavigate(to, options);
        } else {
          reactRouterNavigate(to);
        }
      }
    },
    [isStackSupportApp, reactRouterNavigate]
  );

  return navigate;
};

export default useStackNavigate;
