import React from "react";
import { Outlet } from "react-router-dom";

import OtlLoginDialog from "Components/Common/OtlLoginDialog";
import { useAuth } from "core/context/auth-provider";

const ProtectedRoute = () => {
  const { isLoggedIn, loading } = useAuth();

  if (loading) {
    return null;
  }

  if (!isLoggedIn) {
    return <OtlLoginDialog />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
