// https:// developers.channel.io/docs/web-quickstart#service-for-typescript

export interface Profile {
  [key: string]: string | number | boolean | null | undefined;
}

interface BootOption {
  appearance?: string;
  customLauncherSelector?: string;
  hideChannelButtonOnBoot?: boolean;
  hidePopup?: boolean;
  language?: string;
  memberHash?: string;
  memberId?: string;
  name?: string;
  mobileNumber?: string;
  pluginKey: string;
  profile?: Profile;
  trackDefaultEvent?: boolean;
  trackUtmSource?: boolean;
  unsubscribe?: boolean;
  unsubscribeEmail?: boolean;
  unsubscribeTexting?: boolean;
  zIndex?: number;
}

interface CallbackUser {
  alert: number;
  avatarUrl: string;
  id: string;
  language: string;
  memberId: string;
  name?: string;
  profile?: Profile | null;
  tags?: string[] | null;
  unsubscribeEmail: boolean;
  unsubscribeTexting: boolean;
}

interface Callback {
  (error: Error | null, user: CallbackUser | null): void;
}

interface UpdateUserInfo {
  language?: string;
  profile?: Profile | null;
  profileOnce?: Profile;
  tags?: string[] | null;
  unsubscribeEmail?: boolean;
  unsubscribeTexting?: boolean;
}

interface FollowUpProfile {
  name?: string | null;
  mobileNumber?: string | null;
  email?: string | null;
}

interface EventProperty {
  [key: string]: string | number | boolean | null | undefined;
}

type Appearance = "light" | "dark" | "system" | null;

class ChannelService {
  public loadScript() {
    // eslint-disable-next-line func-style, prefer-rest-params
    (function(){const w=window;if(w.ChannelIO){return;}const ch:IChannelIO=function(){ch.c?.(arguments);};ch.q=[];ch.c=function(args){ch.q?.push(args);};w.ChannelIO=ch;function l(){if(w.ChannelIOInitialized){return;}w.ChannelIOInitialized=true;const s=document.createElement("script");s.type="text/javascript";s.defer=true;s.src="https://cdn.channel.io/plugin/ch-plugin-web.js";const x=document.getElementsByTagName("script")[0];if(x.parentNode){x.parentNode.insertBefore(s,x);}}if(document.readyState==="complete"){l();}else{w.addEventListener("DOMContentLoaded",l);w.addEventListener("load",l);}})();
  }

  // eslint-disable-next-line promise/prefer-await-to-callbacks
  public boot(option: BootOption, callback?: Callback) {
    window.ChannelIO?.("boot", option, callback);
  }

  public shutdown() {
    window.ChannelIO?.("shutdown");
  }

  public showMessenger() {
    window.ChannelIO?.("showMessenger");
  }

  public hideMessenger() {
    window.ChannelIO?.("hideMessenger");
  }

  public openChat(chatId?: string | number, message?: string) {
    window.ChannelIO?.("openChat", chatId, message);
  }

  public track(eventName: string, eventProperty?: EventProperty) {
    window.ChannelIO?.("track", eventName, eventProperty);
  }

  // eslint-disable-next-line promise/prefer-await-to-callbacks
  public onShowMessenger(callback: () => void) {
    window.ChannelIO?.("onShowMessenger", callback);
  }

  // eslint-disable-next-line promise/prefer-await-to-callbacks
  public onHideMessenger(callback: () => void) {
    window.ChannelIO?.("onHideMessenger", callback);
  }

  // eslint-disable-next-line promise/prefer-await-to-callbacks
  public onBadgeChanged(callback: (unread: number, alert: number) => void) {
    window.ChannelIO?.("onBadgeChanged", callback);
  }

  // eslint-disable-next-line promise/prefer-await-to-callbacks
  public onChatCreated(callback: () => void) {
    window.ChannelIO?.("onChatCreated", callback);
  }

  // eslint-disable-next-line promise/prefer-await-to-callbacks
  public onFollowUpChanged(callback: (profile: FollowUpProfile) => void) {
    window.ChannelIO?.("onFollowUpChanged", callback);
  }

  // eslint-disable-next-line promise/prefer-await-to-callbacks
  public onUrlClicked(callback: (url: string) => void) {
    window.ChannelIO?.("onUrlClicked", callback);
  }

  public clearCallbacks() {
    window.ChannelIO?.("clearCallbacks");
  }

  // eslint-disable-next-line promise/prefer-await-to-callbacks
  public updateUser(userInfo: UpdateUserInfo, callback?: Callback) {
    window.ChannelIO?.("updateUser", userInfo, callback);
  }

  // eslint-disable-next-line promise/prefer-await-to-callbacks
  public addTags(tags: string[], callback?: Callback) {
    window.ChannelIO?.("addTags", tags, callback);
  }

  // eslint-disable-next-line promise/prefer-await-to-callbacks
  public removeTags(tags: string[], callback?: Callback) {
    window.ChannelIO?.("removeTags", tags, callback);
  }

  public setPage(page: string) {
    window.ChannelIO?.("setPage", page);
  }

  public resetPage() {
    window.ChannelIO?.("resetPage");
  }

  public showChannelButton() {
    window.ChannelIO?.("showChannelButton");
  }

  public hideChannelButton() {
    window.ChannelIO?.("hideChannelButton");
  }

  public setAppearance(appearance: Appearance) {
    window.ChannelIO?.("setAppearance", appearance);
  }
}

export default new ChannelService();
