const PLATFORM_IOS = "ios app";
const PLATFORM_AND = "android app";
const PLATFORM_WEB = "web";

// TODO: AMPLITUDE에서만 사용하는 용도라서 따로 묶을 필요가 있을 것 같음
/**
 * @deprecated 이 메소드는 더 이상 지원되지 않습니다. useUserAgent hook을 사용하세요.
 */
export const getPlatform = () => {
  const ua = window.navigator.userAgent;
  if (/otl_app_ios/.test(ua)) {
    return PLATFORM_IOS;
  } else if (/otl_app_android/.test(ua)) {
    return PLATFORM_AND;
  } else {
    return PLATFORM_WEB;
  }
};

/**
 * @deprecated 이 메소드는 더 이상 지원되지 않습니다. useUserAgent hook을 사용하세요.
 */
export const getOS = () => {
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;
  const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
  const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
  const iosPlatforms = ["iPhone", "iPad", "iPod"];
  let os = "web";

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "Mac OS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (!os && /Linux/.test(platform)) {
    os = "Linux";
  }

  return os;
};

/**
 * @deprecated 이 메소드는 더 이상 지원되지 않습니다. useUserAgent hook을 사용하세요.
 */
export const isApp = () => {
  if (localStorage.getItem("isApp")) {
    return true;
  }
  const ua = window.navigator.userAgent;
  if (/otl_app_ios/.test(ua)) {
    return true;
  } else if (/otl_app_android/.test(ua)) {
    return true;
  }
  return false;
};

/**
 * @deprecated 이 메소드는 더 이상 지원되지 않습니다. useUserAgent hook을 사용하세요.
 */
export const isAndroidApp = () => {
  const userAgent = window.navigator.userAgent;
  return /otl_app_android/.test(userAgent);
};

/**
 * @deprecated 이 메소드는 더 이상 지원되지 않습니다. useUserAgent hook을 사용하세요.
 */
export const isIOSApp = () => {
  const userAgent = window.navigator.userAgent;
  return /otl_app_ios/.test(userAgent);
};

/**
 * @deprecated 이 메소드는 더 이상 지원되지 않습니다. useUserAgent hook을 사용하세요.
 */
export const isWeb = () => {
  const userAgent = window.navigator.userAgent;
  return !/otl_app/.test(userAgent);
};

/**
 * @deprecated 이 메소드는 더 이상 지원되지 않습니다. useUserAgent hook을 사용하세요.
 */
export const isProduction = (): boolean => {
  return import.meta.env.VITE_API_PROFILE === "production";
};

/**
 * @deprecated 이 메소드는 더 이상 지원되지 않습니다. useUserAgent hook을 사용하세요.
 */
export const isDevelopment = (): boolean => {
  return import.meta.env.VITE_API_PROFILE === "development";
};

/**
 * @deprecated 이 메소드는 더 이상 지원되지 않습니다. useUserAgent hook을 사용하세요.
 */
export const isStage = (): boolean => {
  return import.meta.env.VITE_API_PROFILE === "stage";
};

/**
 * @deprecated 이 메소드는 더 이상 지원되지 않습니다. useUserAgent hook을 사용하세요.
 */
export const isLocal = (): boolean => {
  return import.meta.env.VITE_API_PROFILE === "local";
};

/**
 * @deprecated 이 메소드는 더 이상 지원되지 않습니다. useUserAgent hook을 사용하세요.
 */
export const getAppVersion = () => {
  let appVersion = window.ReactNativeAppVersion || "0.0.0";
  if (appVersion !== "0.0.0") {
    return appVersion;
  }

  const regx = /(otl_app_android|otl_app_ios)\/(\d+).(\d+).(\d+)/;
  const match = window.navigator.userAgent.match(regx);
  if (match && match[0]) {
    appVersion = match[0];
  }

  if (isAndroidApp()) {
    appVersion = appVersion.replace("otl_app_android/", "");
  } else if (isIOSApp()) {
    appVersion = appVersion.replace("otl_app_ios/", "");
  }

  return appVersion;
};

// // TODO: AMPLITUDE, GA에서만 사용하는 용도라서 따로 묶을 필요가 있을 것 같음
/**
 * @deprecated 이 메소드는 더 이상 지원되지 않습니다. useUserAgent hook을 사용하세요.
 */
export const getAppName = () => {
  if (window.appName) {
    return window.appName;
  }

  let appName: AppName = "others";
  const hasVersion: boolean = getAppVersion() !== "0.0.0";

  if (isAndroidApp()) {
    appName = hasVersion ? "android_onthelook_webapp" : "android_another_app";
  } else if (isIOSApp()) {
    appName = hasVersion ? "ios_onthelook_webapp" : "ios_another_app";
  }

  return appName;
};

/**
 * @deprecated 이 메소드는 더 이상 지원되지 않습니다. useUserAgent hook을 사용하세요.
 */
export const isSupportNativeAmplitude = () => {
  if (isWeb()) {
    return false;
  }

  const appVersion: string = getAppVersion();
  const appVersionArr: string[] = appVersion.split(".");

  if (appVersionArr && appVersionArr.length < 2) {
    return false;
  }

  const major = Number(appVersionArr[0]);
  const minor = Number(appVersionArr[1]);
  const patch = Number(appVersionArr[2]);

  if (isIOSApp() && major >= 0 && minor >= 2 && patch >= 26) {
    // 0.2.26
    return true;
  } else if (isAndroidApp() && major >= 0 && minor >= 2 && patch >= 40) {
    // 0.2.40
    return true;
  }

  return false;
};
