import React, { createContext } from "react";

import useUserAgent from "core/user-agent/use-user-agent";

interface KakaoAdProviderProps {
  children: React.ReactNode;
}

const KakaoAdContext = createContext<Record<string, unknown> | undefined>(
  undefined
);

const initKakaoAd = (url: string) => {
  const head = document.querySelector("head");
  const script = document.createElement("script");
  script.defer = true;
  script.setAttribute("src", url);
  head?.appendChild(script);
};

const KakaoAdProvider = ({ children }: KakaoAdProviderProps) => {
  const { isSearchEngineBot } = useUserAgent();

  if (!window?.kakaoPixel && !isSearchEngineBot) {
    initKakaoAd("//t1.daumcdn.net/kas/static/kp.js");
  }
  return (
    <KakaoAdContext.Provider value={undefined}>
      {children}
    </KakaoAdContext.Provider>
  );
};

export default KakaoAdProvider;
