export const LocalStorageProperties = {
  isApp: "isApp",
  age: "ut_a",
  gender: "ut_g",
  selectedStyleIds: "ut_s",
  selectedStyleItems: "ut_s_it",
  height: "ut_h",
  isSetTaste: "isSetTaste",
  isTasteSaved: "isTasteSaved",
  firstAppLaunch: "otl_tt_isFirst",
  postFilter: "initFilter",
  otlUUID: "otl_uuid",
  userId: "userId",
  creatorId: "creatorId",
  authToken: "jwt",
  lazyFollowers: "OTL_LAZY_FOLLOW",
  amplitudeId: "apdId",
  aKey: "akey",
  fcm: "fcm",
  gcid: "gcid",
  lang: "i18nextLng",
  isHomeTabUpdated: "otl_home_tab_updated_0826",
  otlAtInsightTip: "otl_at_insight_tip",
  otlAtMoodPostsTip: "otl_at_mood_posts_tip",
  otlLoginCallbackUrl: "otl_login_callbackUrl",
  recentSerchQueries: "otl_rq",
  tempSavedPost: "temp_saved_post",
  tempSavedPoints: "temp_saved_points",
  tempSavedPostItems: "temp_saved_post_items",
  homeTab: "home_tab",
  authState: "auth_state",
  seenPosts: "seen_posts",
  tryAuthNumberInfo: "try_auth_number_info",
  FB_TOKEN_STATUS: "FB_TOKEN_STATUS",
  FB_TOKEN: "FB_TOKEN",
  postClickCnt: "post_click_cnt",
} as const;
export type LocalStorageKeys = keyof typeof LocalStorageProperties;

type WebStorageType = "localStorage" | "sessionStorage";

// 참고: https://github.com/titicacadev/triple-frontend/tree/main/packages/web-storage/src

/**
 * 주어진 error가 WebStorage의 용량을 모두 사용했다는 에러인지 확인하는 함수
 * 참고: https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#testing_for_availability
 * @param error
 */
export function checkQuotaExceededError(error: Error): boolean {
  return (
    error instanceof DOMException &&
    // everything except Firefox
    (error.code === 22 ||
      // Firefox
      error.code === 1014 ||
      // test name field too, because code might not be present
      // everything except Firefox
      error.name === "QuotaExceededError" ||
      // Firefox
      error.name === "NS_ERROR_DOM_QUOTA_REACHED")
  );
}

/**
 * WebStorage API를 사용할 수 있는지 확인하는 함수
 * 참고: https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#testing_for_availability
 * @param type 확인할 storage 이름
 * @returns 사용 가능 여부
 */
export function storageAvailable(type: WebStorageType) {
  let storage;

  try {
    storage = window[type];

    const x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);

    return true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    return (
      checkQuotaExceededError(e) &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
}

export function getWebStorage(type: WebStorageType = "localStorage") {
  if (typeof window === "undefined") {
    throw new Error("Not Browser");
  }

  const storage = window[type];

  return {
    get length() {
      return storage.length;
    },

    key(index: number): string | null {
      return storage.key(index);
    },

    getItem(key: LocalStorageKeys): string | null {
      return storage.getItem(LocalStorageProperties[key.toString()]);
    },

    setItem(key: LocalStorageKeys, value: string) {
      try {
        return storage.setItem(LocalStorageProperties[key.toString()], value);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (checkQuotaExceededError(error)) {
          throw Error("storage quota Exceeded");
        }

        throw error;
      }
    },

    removeItem(key: LocalStorageKeys) {
      return storage.removeItem(LocalStorageProperties[key.toString()]);
    },

    clear() {
      return storage.clear();
    },
  };
}
