import amplitude from "amplitude-js";

import {
  getOS,
  isAndroidApp,
  isDevelopment,
  isIOSApp,
  isLocal,
  getPlatform,
  getAppName,
  getAppVersion,
} from "./UserAgentUtil";
import { isSupportIOSJsBridgeV2 } from "./DeviceUtil";
import { printError } from "./LogUtil";

/**
 * @deprecated 이 메소드는 더 이상 지원되지 않습니다. useAmplitude hook을 사용하세요.
 */
const sendLogEventForWeb = (args) => {
  try {
    const amp = amplitude.getInstance();
    if (args.length >= 2) {
      amp.logEvent(args[0], args[1]);
      return;
    }
    if (args.length === 1) {
      amp.logEvent(args[0]);
      return;
    }
  } catch (error) {
    if (error instanceof Error) {
      printError({ text: error.message });
    }
  }
};

/**
 * @deprecated 이 메소드는 더 이상 지원되지 않습니다. useAmplitude hook을 사용하세요.
 */
const sendLogEventForAndroid = (args) => {
  try {
    const w = window as Window;
    const bridge = w.OTL;

    if (!bridge || !bridge.sendAmpLogEvent) {
      return;
    }

    if (args.length >= 2) {
      bridge.sendAmpLogEvent(args[0], JSON.stringify(args[1]));
      return;
    }

    if (args.length === 1) {
      bridge.sendAmpLogEvent(args[0], "");
      return;
    }
  } catch (error) {
    if (error instanceof Error) {
      printError({ text: error.message });
    }
  }
};

/**
 * @deprecated 이 메소드는 더 이상 지원되지 않습니다. useAmplitude hook을 사용하세요.
 */
const sendLogEventForIOS = (args) => {
  if (!isSupportIOSJsBridgeV2()) {
    printError({ text: "not support js bridge on iOS" });
    return;
  }
  try {
    const w = window as Window;
    const bridge =
      w &&
      w.webkit &&
      w.webkit.messageHandlers &&
      w.webkit.messageHandlers.OTL_V2;

    if (!bridge) {
      return;
    }

    if (args.length >= 2) {
      const msg = {
        method: "sendAmpLogEvent",
        data: {
          eventName: args[0],
          eventProps: args[1],
        },
      };
      bridge.postMessage(JSON.stringify(msg));
      return;
    }

    if (args.length === 1) {
      const msg = {
        method: "sendAmpLogEvent",
        data: {
          eventName: args[0],
          eventProps: {},
        },
      };
      bridge.postMessage(JSON.stringify(msg));
      return;
    }
  } catch (error) {
    if (error instanceof Error) {
      printError({ text: error.message });
    }
  }
};

/**
 * @deprecated 이 메소드는 더 이상 지원되지 않습니다. useAmplitude hook을 사용하세요.
 */
export const sendAmpLogEvent = (...args) => {
  const platform = getPlatform() || "web";

  if (isDevelopment() && isLocal()) {
    printError({ text: `sendAmpLogEvent - ${platform}` });
  }

  if (isIOSApp()) {
    sendLogEventForIOS(args);
    return;
  }

  if (isAndroidApp()) {
    sendLogEventForAndroid(args);
    return;
  }

  sendLogEventForWeb(args);
};

/**
 * @deprecated 이 메소드는 더 이상 지원되지 않습니다. useAmplitude hook을 사용하세요.
 */
export const sendPageViewEvent = (
  source: string,
  version: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  gaValue: string
) => {
  sendAmpLogEvent("Page viewed", {
    Where: String(source),
    Path: window.location.pathname,
    Version: String(version), // 1,2,3,4
  });
};

const getDefaultUserProps = () => {
  const os = getOS();

  return {
    os,
    appName: getAppName(),
    appVersion: getAppVersion(),
    platform: getPlatform(),
    isLogin: false,
    isNative: false,
    age: localStorage.getItem("ut_a") || "",
    gender: localStorage.getItem("ut_g") || "",
    height: localStorage.getItem("ut_h") || "",
    style_taste: localStorage.getItem("ut_s") || "",
    otluuid: localStorage.getItem("otl_uuid") || "",
    uuid: localStorage.getItem("otl_uuid") || "",
  };
};

/**
 * It should be called by the all user
 * @param userProps
 * @returns
 */
/**
 * @deprecated 이 메소드는 더 이상 지원되지 않습니다. useAmplitude hook을 사용하세요.
 */
export const setUserProps = (userProps) => {
  const props = getDefaultUserProps();

  userProps = {
    ...props,
    ...userProps,
  };

  const w = window as Window;
  const isSupportIOS = isIOSApp() && isSupportIOSJsBridgeV2();
  const isSupportAndroid = isAndroidApp() && w.OTL && w.OTL.setUserProps;

  try {
    // iPhone
    if (isSupportIOS) {
      const msg = {
        method: "setUserProps",
        data: userProps,
      };
      w.webkit.messageHandlers.OTL_V2.postMessage(JSON.stringify(msg));
      return;
    }

    // Android
    if (isSupportAndroid) {
      w.OTL.setUserProps(JSON.stringify(userProps));
      return;
    }

    // Others
    amplitude.getInstance().setUserId(userProps.userId || 0);
    amplitude.getInstance().setUserProperties({
      ...userProps,
      isNative: false,
    });
  } catch (error) {
    if (error instanceof Error) {
      printError({ text: error.message });
    }
  }
};

/**
 * It should be called by the 'logged-in' user
 * @param userProps
 * @returns
 */
/**
 * @deprecated 이 메소드는 더 이상 지원되지 않습니다. useAmplitude hook을 사용하세요.
 */
export const setLoginUserProps = (userProps) => {
  const w = window as Window;
  const isSupportIOS = isIOSApp() && isSupportIOSJsBridgeV2();
  const isSupportAndroid = isAndroidApp() && w.OTL && w.OTL.setLoginUserProps;

  try {
    // iPhone
    if (isSupportIOS) {
      const msg = {
        method: "setLoginUserProps",
        data: userProps,
      };
      w.webkit.messageHandlers.OTL_V2.postMessage(JSON.stringify(msg));
      return;
    }

    // Android
    if (isSupportAndroid) {
      w.OTL.setLoginUserProps(JSON.stringify(userProps));
      return;
    }

    // Others
    amplitude.getInstance().setUserId(userProps.userId || 0);
    amplitude.getInstance().setUserProperties({
      ...userProps,
      isNative: false,
    });
  } catch (error) {
    if (error instanceof Error) {
      printError({ text: error.message });
    }
  }
};

/**
 * It should be called by the 'logged-out' user
 * @param userProps
 * @returns
 */
/**
 * @deprecated 이 메소드는 더 이상 지원되지 않습니다. useAmplitude hook을 사용하세요.
 */
export const setLogoutUserProps = () => {
  const w = window as Window;
  const isSupportIOS = isIOSApp() && isSupportIOSJsBridgeV2();
  const isSupportAndroid = isAndroidApp() && w.OTL && w.OTL.setLogoutUserProps;

  try {
    // iPhone
    if (isSupportIOS) {
      const msg = {
        method: "setLogoutUserProps",
        data: {},
      };
      w.webkit.messageHandlers.OTL_V2.postMessage(JSON.stringify(msg));
      return;
    }

    // Android
    if (isSupportAndroid) {
      w.OTL.setLogoutUserProps();
      return;
    }

    // Others
    amplitude.getInstance().setUserId(null);
    amplitude.getInstance().setUserProperties(getDefaultUserProps());
  } catch (error) {
    if (error instanceof Error) {
      printError({ text: error.message });
    }
  }
};
