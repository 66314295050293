import React from "react";
import styled from "styled-components";

import ArrowBack from "images/icons/ic_back.png";
import Setting from "images/icons/setting.png";
import Close from "images/icons/ic_close.png";
import Search from "images/icons/search.svg";
import Home from "images/icons/home_nor.png";
import { StackLinkWrapper } from "elements/link";

type IconNames = "back" | "close" | "search" | "setting" | "home";
// TODO: Asset URL로 변경 필요
const ICON_URL_BY_NAMES: { [key in IconNames]: string } = {
  back: ArrowBack,
  close: Close,
  search: Search,
  setting: Setting,
  home: Home,
};

interface NavBarProps {
  children: React.ReactNode;
  marginLeft?: string;
  marginRight?: string;
  gap?: string;
}

interface NavBarItemProps {
  onClick?: () => void;
  children?: React.ReactNode;
  icon?: IconNames;
  marginLeft?: string;
  marginRight?: string;
  style?: React.CSSProperties;
  href?: string;
}

interface NavBarFrameProps {
  gap?: string;
  marginRight?: string;
  marginLeft?: string;
}

const NavBarFrame = styled.ul<NavBarFrameProps>`
  display: flex;
  gap: ${({ gap }) => gap || "8px"};
  margin-left: ${({ marginLeft }) => marginLeft || 0};
  margin-right: ${({ marginRight }) => marginRight || 0};
  align-items: center;
`;

const NavBarItemContainer = styled.li<NavBarItemProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  margin-left: ${({ marginLeft }) => marginLeft || 0};
  margin-right: ${({ marginRight }) => marginRight || 0};

  /* react의 style Props 사용할 수 있도록 추가 */
  ${(props) =>
    props.style &&
    Object.keys(props.style)
      .map((key) => `${key}: ${props.style?.[key]};`)
      .join("")}

  cursor: pointer;
`;

const NavBarIcon = styled.img<NavBarItemProps>`
  width: ${({ icon }) => (icon ? "32px" : null)};
  height: ${({ icon }) => (icon ? "32px" : null)};
`;

const NavBar = ({ children, ...props }: NavBarProps) => (
  <NavBarFrame {...props}>{children}</NavBarFrame>
);

const NavBarItem = ({ href, icon, children, ...props }: NavBarItemProps) => {
  return (
    <NavBarItemContainer {...props}>
      <StackLinkWrapper href={href}>
        {icon ? (
          <NavBarIcon
            src={ICON_URL_BY_NAMES[icon] || ""}
            icon={icon}
            alt={`${icon} icon`}
          />
        ) : (
          children
        )}
      </StackLinkWrapper>
    </NavBarItemContainer>
  );
};

NavBar.Item = NavBarItem;

export default NavBar;
