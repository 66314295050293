import React from "react";
import styled, { css, keyframes } from "styled-components";

import BrandCreatorPostSkeleton from "./brand-creator-post-skeleton";

import DividingLine from "Routes/post/search-result/Component/dividing-line";
import ItemOrderSelector from "Components/ItemOrderSelector";
import ProductGridSkeleton from "Components/ProductGrid/product-grid-skeleton";

export interface BrandInformationPropsType {
  name: string;
  nameKr: string;
  brandLogoImageUrl: string;
}
const fadeIn = keyframes`
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
`;

const shimmer = keyframes`
  0% {
    background-position: -300px 0;
  }

  100% {
    background-position: 300px 0;
  }
`;

const shimmerAnimation = css`
  background: linear-gradient(90deg, #f4f4f4 25%, #e0e0e0 50%, #f4f4f4 75%);
  background-size: 300px 100%;
  animation: ${shimmer} 3s infinite linear;
`;

const Container = styled.section`
  width: 100%;
  padding: 0 24px;
`;

const FlexWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 0;
`;

const BrandLogoWrap = styled.span`
  position: relative;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BrandLogo = styled.div`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
  background-color: #f4f4f4;
`;

const BrandNameWrap = styled.span`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  gap: 4px;
`;

const BrandName = styled.h1`
  height: 26px;
  width: 120px;
  ${shimmerAnimation}
`;

const BrandNameKr = styled.h2`
  height: 18px;
  width: 80px;
  ${shimmerAnimation}
`;

const BrandIntroduction = styled.div`
  height: 42px;
  width: 100%;
  border-radius: 8px;
  ${shimmerAnimation}
`;

const GridWrap = styled.div`
  display: flex;
  gap: 2px;
  overflow-x: scroll;
  padding: 0 24px 28px;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const PostHeader = styled.span`
  padding: 28px 24px 16px;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 1;
`;

const LeftWrap = styled.div`
  display: flex;
  align-items: center;
`;

const ProductTotalCount = styled.div`
  font-size: 15px;
  line-height: 1.2;
  font-weight: 700;
  margin-right: 12px;
  animation: ${fadeIn} 0.8s ease;
`;

const CategoriesWrap = styled.div`
  position: relative;
  width: 100%;
  height: 40px;
  ${shimmerAnimation}
  margin-top: 12px;
`;

const BrandDetailSkeleton = () => {
  return (
    <>
      <Container>
        <FlexWrap>
          <BrandLogoWrap>
            <BrandLogo />
          </BrandLogoWrap>
          <BrandNameWrap>
            <BrandName />
            <BrandNameKr />
          </BrandNameWrap>
        </FlexWrap>
        <BrandIntroduction />
      </Container>
      <GridWrap>
        <BrandCreatorPostSkeleton amount={3} />
      </GridWrap>
      <DividingLine />
      <CategoriesWrap />
      <div
        style={{
          height: window.innerHeight - (56 + 60),
        }}
      >
        <PostHeader>
          <LeftWrap>
            <ProductTotalCount
              style={{
                width: 80,
                height: 18,
                backgroundColor: "#f4f4f4",
              }}
            />
          </LeftWrap>
          <ItemOrderSelector
            sortField={"score"}
            sortOrder={"DESC"}
            setOrderFn={() => null}
          />
        </PostHeader>
        <ProductGridSkeleton amount={6} />
      </div>
    </>
  );
};

export default BrandDetailSkeleton;
