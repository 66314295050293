import React from "react";
import styled, { keyframes } from "styled-components";

import { useMediaQuery } from "core/screen";

const GridWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 2px;

  @media (min-width: 743px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  margin-bottom: 24px;
`;

const shimmer = keyframes`
  0% {
    background-position: -300px 0;
  }

  100% {
    background-position: 300px 0;
  }
`;

const Eelement = styled.div`
  margin-bottom: 24px;
  cursor: pointer;
`;

const BrandName = styled.p`
  height: 13px;
  width: 50%;
  margin-bottom: 6px;
  background: linear-gradient(90deg, #f4f4f4 25%, #e0e0e0 50%, #f4f4f4 75%);
  background-size: 300px 100%;
  animation: ${shimmer} 2.5s infinite linear;
`;

const ProductName = styled.p`
  height: 26px;
  width: 90%;
  margin-bottom: 8px;
  background: linear-gradient(90deg, #f4f4f4 25%, #e0e0e0 50%, #f4f4f4 75%);
  background-size: 300px 100%;
  animation: ${shimmer} 2.5s infinite linear;
`;

const Price = styled.p`
  height: 14px;
  width: 30%;
  background: linear-gradient(90deg, #f4f4f4 25%, #e0e0e0 50%, #f4f4f4 75%);
  background-size: 300px 100%;
  animation: ${shimmer} 2.5s infinite linear;
`;

const ImageWrap = styled.div`
  position: relative;
  padding-top: 120%;

  & > div {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
  }

  ::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    background-color: rgba(0, 0, 0, 0.03);
    content: "";
  }
`;

const InnerImage = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  object-fit: contain;
  background-color: #f4f4f4;
`;

const InfoWrap = styled.div`
  margin: 12px 0 0 16px;
`;

const ProductGridSkeletonElement = () => {
  return (
    <Eelement>
      <ImageWrap>
        <InnerImage />
      </ImageWrap>
      <InfoWrap>
        <BrandName />
        <ProductName />
        <Price />
      </InfoWrap>
    </Eelement>
  );
};

const ProductGridSkeleton = ({ amount }: { amount: number }) => {
  const isMobileView = useMediaQuery("(max-width: 743px)");
  return (
    <GridWrap>
      {[...Array(isMobileView ? amount : 9).keys()].map((index) => (
        <ProductGridSkeletonElement key={index} />
      ))}
    </GridWrap>
  );
};
export default ProductGridSkeleton;
