import React from "react";
import styled from "styled-components";

const Dividing = styled.div`
  width: 100%;
  height: 10px;
  background-color: #f4f4f4;
`;

const DividingLine = () => {
  return <Dividing />;
};

export default DividingLine;
