import { useCallback } from "react";

import useUserAgent from "../user-agent/use-user-agent";

import { sendMessageToNative } from "./bridge-handler";

import {
  isSupportVibrate,
  vibrate as legacyVibrate,
} from "Functions/DeviceUtil";

const useNativeEvent = () => {
  const { appVersion } = useUserAgent();
  // TODO: RN이 배포되면 삭제되어야 함
  const isLegacyApp = appVersion ? appVersion.split(".")[0] === "0" : false;

  const vibrate = useCallback(() => {
    if (!isSupportVibrate()) {
      return;
    }
    if (isLegacyApp) {
      legacyVibrate();
    } else {
      sendMessageToNative("NATIVE_EVENT", {
        method: "vibrate",
      });
    }
  }, [isLegacyApp]);

  const requestAppReview = () => {
    sendMessageToNative("NATIVE_EVENT", {
      method: "request-review",
    });
  };

  const shareLink = (url: string) => {
    sendMessageToNative("NATIVE_EVENT", {
      method: "shareLink",
      params: {
        url,
      },
    });
  };
  return { vibrate, requestAppReview, shareLink };
};

export { useNativeEvent };
