import { v4 as uuidv4 } from "uuid";
import React, { createContext, useEffect } from "react";

import { getWebStorage } from "core/storage";

interface UUIDProviderProps {
  children: React.ReactNode;
}

interface UUIDContextProps {
  uuid: string;
}

const UUIDContext = createContext<undefined | UUIDContextProps>(undefined);
const storage = getWebStorage();

const UUIDProvider = ({ children }: UUIDProviderProps) => {
  const uuid = storage.getItem("otlUUID") || uuidv4();

  useEffect(() => {
    storage.setItem("otlUUID", uuid);
  }, [uuid]);

  return (
    <UUIDContext.Provider value={{ uuid }}>{children}</UUIDContext.Provider>
  );
};

function useUUID() {
  const context = React.useContext(UUIDContext);
  if (context === undefined) {
    throw new Error("useUUID must be used within a AmplitudeProvider");
  }
  return context;
}

export { UUIDProvider, useUUID };
