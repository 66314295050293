import React, { createContext, useEffect, useMemo } from "react";

import { useAuth } from "../auth-provider";

import { User } from "types/generated/graphql";
import useLangauge, { SupportLanguage } from "core/locale/use-language";
import useUserAgent from "core/user-agent/use-user-agent";
import ChannelService, { Profile } from "libs/channel-talk/channel-service";

interface ChannelTalkProviderProps {
  children: React.ReactNode;
}

const createChannelIOUser = (
  user?: User
): {
  profile: Profile;
  memberId?: string;
  name?: string;
  mobileNumber?: string;
} => {
  return {
    profile: {
      email: user?.email || undefined,
      name: `${user?.name || "사용자"}_${user?.id || "비회원"}${
        user?.isCreator ? "_크리에이터" : ""
      }`,
      mobileNumber: user?.phone || undefined,
      isCreator: user?.isCreator ? "true" : "false",
      userId: user?.id ? String(user?.id) : undefined,
      gender: user?.gender ? user.gender : undefined,
    },
    memberId: user?.id ? String(user?.id) : undefined,
    name: user?.name || undefined,
    mobileNumber: user?.phone || undefined,
  };
};

// https://developers.channel.io/docs/web-boot-option#language
type LanguageMap = Record<SupportLanguage, "ko" | "ja" | "en">;

const languageMap: LanguageMap = {
  korean: "ko",
  japanese: "ja",
  english: "en",
};

const ChannelTalkContext = createContext<Record<string, unknown> | undefined>(
  undefined
);

/**
 * 채널톡을 사용하기 위한 Provider입니다.
 * 최초 화면 구성 시 호출이 필요한 경우가 아니라면 lazyload 합니다.
 * 검색엔진이 접근 시에는 호출하지 않습니다.
 */
const ChannelTalkProvider = ({ children }: ChannelTalkProviderProps) => {
  const { user, loading } = useAuth();
  const { language } = useLangauge();
  const { isSearchEngineBot } = useUserAgent();
  const channelTalkUser = useMemo(() => {
    if (isSearchEngineBot) return undefined;
    return createChannelIOUser(user);
  }, [isSearchEngineBot, user]);

  useEffect(() => {
    const init = () => {
      if (!isSearchEngineBot && !loading) {
        ChannelService.loadScript();
        setTimeout(() => {
          ChannelService.boot({
            pluginKey: import.meta.env.VITE_CHANNEL_TALK_ID as string,
            language: languageMap[language],
            hideChannelButtonOnBoot: true,
            ...channelTalkUser,
          });
        }, 1000);
      }
      window.removeEventListener("scroll", init);
    };

    // 사용자 스크롤이 발생하기 전에 채널톡을 호출하지 않습니다.
    window.addEventListener("scroll", init);
  }, [channelTalkUser, isSearchEngineBot, language, loading]);

  return (
    <ChannelTalkContext.Provider value={undefined}>
      {children}
    </ChannelTalkContext.Provider>
  );
};

const useChannelIOApi = () => {
  return {
    showMessenger: ChannelService.showMessenger,
    hideMessenger: ChannelService.hideMessenger,
    showChannelButton: ChannelService.showChannelButton,
    hideChannelButton: ChannelService.hideChannelButton,
  };
};

export { ChannelTalkProvider, useChannelIOApi };
