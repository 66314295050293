import "react-toastify/dist/ReactToastify.min.css";
import "reactjs-popup/dist/index.css";

import { createGlobalStyle } from "styled-components";

import { maxWidth } from "./mixin";

import { getSafeAreaInsetBottom } from "Functions/LayoutUtil";
import { isV2App } from "core/user-agent/use-user-agent";

const GlobalStyle = createGlobalStyle`
  :root {
    /* z-index */
    --sub-nav-index: 4;
    --nav-index: 5;
    --dimmer-index: 10;
    --toast-index: 50;
    --modal-overay-index: 100;
    --modal-index: 100;
    --app-update-alert-index: 1002;
    --tooltip-index: 4;
    --max-width: 743px;

    /* height */
    --nav-bar-height: 56px;
    --app-download-button-height: 56px;
    --bottom-nav-bar-height: ${isV2App() ? "0px" : "60px"};
    --search-nav-bar-height: 38px;
  }

  body {
    margin: 0;
    font-family: Montserrat, Pretendard, sans-serif;
    -webkit-tap-highlight-color: transparent;
    background-color: #fff;
  }

  input,
  select,
  textarea,
  button { outline: none; }

  div#root {
    height: 100%;
  }

  section {
    height: 100%;
    ${maxWidth}
    margin: 0 auto !important;
    float: none !important;
  }

  * {
    box-sizing: border-box;
    background-color: transparent;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  a {
    text-decoration: none;
    color: inherit;
    outline: none;
  }

  a:hover,
  a:focus {
    color: inherit;
  }

  img {
    vertical-align: bottom;
  }

  input:focus,
  textarea:focus {
    outline: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  input::placeholder {
    color: #ccc;
    font-size: 16px;
    font-weight: normal;
    display: flex;
    align-items: center;
    padding-top: 0;
    padding-left: 4px;
    font-family: Montserrat, Pretendard, sans-serif;
  }

  .Toastify__toast-container {
    min-height: 48px;
    width: 100%;
    ${maxWidth}
    position: fixed;
    bottom: 57px;
    pointer-events: none;
  }

  .Toastify__toast {
    min-height: 48px;
  }

  .Toastify__progress-bar {
    display: gray;
  }

  .Toastify__close-button {
    align-self: initial;
  }

  .otl_tostify {
    ${maxWidth}
    background-color: rgba(34, 34, 34, 0.9);
    margin: 20px;
    border-radius: 8px;
    min-height: 48px;
    color: white;
    font-size: 14px;
    text-align: center;
    white-space: pre-wrap;
    font-family: Montserrat, Pretendard, sans-serif;
  }

  .otl_tostify.longform {
    margin-top: 80px;
  }

  .otl_tostify.error {
    background-color: rgba(231, 76, 60, 0.9);
  }

  .otl_tostify.bottom {
    margin-bottom: 20px;
  }

  .not-scroll {
    overflow: hidden;
  }

  .button {
    box-shadow: 0 0 0;
    transform: translateY(0);
  }

  .button:active {
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .button:focus-visible {
    outline: none;
  }

  .medium-zoom-overlay {
    opacity: 0.8 !important;
    z-index: 102 !important;
  }

  .medium-zoom-image {
    z-index: 103 !important;
  }

  .medium-zoom-image--opened {
    height: auto !important;
  }

  .launcherIcon {
    margin-bottom: calc(16px + ${getSafeAreaInsetBottom("env")}) !important;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  [data-rsbs-header]::before {
    width: 56px !important;
    height: 5px !important;
    top: 8px !important;
  }

  [data-rsbs-backdrop] {
    z-index: 102 !important;
  }

  [data-rsbs-overlay] {
    ${maxWidth}
    margin-left: auto !important;
    margin-right: auto !important;
    z-index: 102 !important;
  }

  [data-rsbs-header] {
    padding-top: 12px !important;
  }

  [data-rsbs-footer] {
    box-shadow: none !important;
  }

  .otl-button.primary.active {
    background-color: #222;
    box-shadow: 0 2px 20px 0 rgba(34, 34, 34, 0.4);
  }

  .otl-button.secondary.active {
    background: #ff2c58;
    box-shadow: 0 2px 20px rgba(255, 44, 88, 0.4259);
  }

  .otl-button.inactive {
    background-color: #ccc;
    box-shadow: 0 2px 20px 0 rgba(34, 34, 34, 0.2);
  }

  button {
    border: none;
    background-color: transparent;
    font-family: inherit;
    padding: 0;
    cursor: pointer;
  }

  .cart-icon-wrap {
    width: 32px;
    height: 32px;
  }

  .custom-bottom-sheet {
    ${maxWidth}
    margin: auto !important;
  }
`;

export default GlobalStyle;
