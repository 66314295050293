import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as Sentry from "@sentry/react";

import {
  getAppName,
  getAppVersion,
  isSupportNativeAmplitude,
  getPlatform,
} from "../../Functions/UserAgentUtil";
import { DatetimeUtil } from "../../Functions/DatetimeUtil";

import { getWebStorage } from "core/storage";
import { useAmplitude } from "utils/amplitude/use-amplitude";
import { User, useGetLoginUserQuery } from "types/generated/graphql";
import { sendMessageToNative } from "core/channels/bridge-handler";
import useUserAgent from "core/user-agent/use-user-agent";
import useBroadcastRefetch from "core/channels/use-broadcast-refetch";

interface AuthProviderProps {
  children: React.ReactNode;
}
export type LoginType = "apple" | "kakao" | "line" | "email";
interface LoginParams {
  token: string;
  userParams: {
    userId: number;
    loginType: LoginType;
  };
  onCompleted?: () => void;
}

interface AuthContextProps {
  user?: User;
  isLoggedIn: boolean;
  login: (params: LoginParams) => void;
  logout: (params?: { onCompleted?: () => void }) => void;
  loading: boolean;
}

// TODO: 필수 필드 정리 필요
const createAmplitudeUser = (user: User) => {
  const storage = getWebStorage();
  return {
    userId: user.id,
    loginType: user.kakaoId ? "kakao" : "email",
    joinedAt: DatetimeUtil.getFormatDate(user.createdAt),
    platform: getPlatform(),
    isCreator: user.isCreator,
    creatorId: user.creatorId,
    otluuid: storage.getItem("otlUUID"),
    uuid: storage.getItem("otlUUID"),
    instagramId: user.instagramId || "",
    isEnablePush: user.isAgreeMarketing,
    isLogin: true,
    os: (window as Window).os,
    appName: getAppName(),
    appVersion: getAppVersion(),
    migratedAt: DatetimeUtil.getFormatDate(user.creator?.createdAt || ""),
    isNative: isSupportNativeAmplitude(),
    age: storage.getItem("age"),
    gender: storage.getItem("gender") || user.gender,
    height: storage.getItem("height"),
    style_taste: storage.getItem("selectedStyleIds"),
  };
};

const AuthContext = createContext<undefined | AuthContextProps>(undefined);

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<User | undefined>(undefined);
  const { appVersion } = useUserAgent();
  const [loading, setLoading] = useState(true);
  const { schemasToRefetch, refetchSchema } = useBroadcastRefetch();

  const isV2App = useMemo(() => {
    return Number(appVersion?.split(".")[0]) >= 2;
  }, [appVersion]);

  const storage = getWebStorage();
  const {
    logout: logoutAmplitude,
    track,
    login: loginAmplitude,
    updateUser: updateAmplitudeUser,
  } = useAmplitude();

  // 2.X.X App Token과 web Token를 동기화합니다.
  useEffect(() => {
    const token = storage.getItem("authToken");
    if (isV2App && token) {
      sendMessageToNative("AUTH", {
        method: "login",
        params: {
          token,
        },
      });
    }
  }, []);

  const { refetch: refetchUser } = useGetLoginUserQuery({
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      if (res.GetLoginUser.items) {
        const loginUser = res.GetLoginUser.items;
        // 재로그인을 오랫동안 안한 유저를 위해 User정보를 가져오는 곳에서 login 처리를 진행합니다.
        loginAmplitude({
          userId: loginUser.id,
        });
        setUser(loginUser);
        updateAmplitudeUser(createAmplitudeUser(loginUser));
        storage.setItem("userId", String(loginUser.id));

        // Sentry에 User 정보를 등록합니다.
        Sentry.configureScope((scope) => {
          scope.setUser({
            id: String(loginUser.id),
          });
        });
      }
      // TODO: error임에도 onError로 내려오지 않음. 추후 수정 필요
      if (res.GetLoginUser?.error === "not Login") {
        storage.removeItem("authToken");
        storage.removeItem("userId");
        storage.removeItem("creatorId");

        // Sentry에서 User 정보를 제거합니다.
        Sentry.setUser(null);
        setUser(undefined);
      }
      setLoading(false);
    },
  });

  const login = useCallback(
    async ({ token, userParams, onCompleted }: LoginParams) => {
      setLoading(true);
      storage.removeItem("lazyFollowers");
      storage.setItem("authToken", token);

      refetchUser();
      loginAmplitude({
        userId: userParams.userId,
        loginType: userParams.loginType,
      });
      track("login success");

      // V2 App에서는 Native로 로그인을 요청합니다.
      if (isV2App) {
        sendMessageToNative("AUTH", {
          method: "login",
          params: { token: storage.getItem("authToken") || "" },
        });
      }
      refetchSchema("auth");
      await onCompleted?.();
    },
    [loginAmplitude, refetchUser, storage, track, isV2App, refetchSchema]
  );

  const logout = useCallback(
    async (params?: { onCompleted?: () => void }) => {
      setLoading(true);
      setUser(undefined);
      storage.removeItem("authToken");
      storage.removeItem("userId");
      storage.removeItem("creatorId");
      track("logout");
      logoutAmplitude();
      Sentry.setUser(null);

      // V2 App에서는 Native로 로그아웃을 요청합니다.
      if (isV2App) {
        sendMessageToNative("AUTH", { method: "logout" });
      }
      refetchSchema("auth");
      await params?.onCompleted?.();
      setLoading(false);
    },
    [storage, track, logoutAmplitude, isV2App, refetchSchema]
  );

  useEffect(() => {
    if (schemasToRefetch.includes("auth")) {
      refetchUser();
    }
  }, [refetchUser, schemasToRefetch]);

  const isLoggedIn = useMemo(() => !!user, [user]);

  return (
    <AuthContext.Provider value={{ isLoggedIn, user, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const authContext = useContext(AuthContext);
  if (authContext === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return authContext;
};

export { AuthProvider, useAuth };
