import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { Trans, useTranslation } from "react-i18next";

import useUserAgent from "core/user-agent/use-user-agent";
import { useAmplitude } from "utils/amplitude/use-amplitude";

const Container = styled.div`
  width: 100%;
`;

const MainWrapper = styled.div`
  display: flex;
  padding: 20px 32px 24px;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  padding: 0 16px 16px;
`;

const Title = styled.h1`
  font-size: 20px;
  font-weight: 700;
  line-height: 1.3;
  color: #000;
  display: flex;
  flex-direction: column;
  white-space: pre-line;
`;

const SubTitle = styled.h2`
  font-size: 15px;
  font-weight: 400;
  line-height: 1.4;
  color: #000;
  white-space: pre-line;
`;

const Block = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  flex-direction: row;

  @media (min-width: 743px) {
    flex-direction: column;
    gap: 12px;
  }
`;

const BlockWrap = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;

  @media (min-width: 743px) {
    flex-direction: row;
  }
`;

const BlockImg = styled.img`
  width: 52px;
  height: 52px;
`;

const NextButton = styled.button`
  padding: 12px 24px;
  border-radius: 8px;
  background: #f4f4f4;
  color: #000;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  white-space: nowrap;
  height: 52px;
`;

const highlight = keyframes`
  100% {
    background-position: right center;
  }
`;

const DownloadButton = styled(NextButton)`
  width: -webkit-fill-available;
  color: #fff;
  font-weight: 700;
  height: 52px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: linear-gradient(90deg, #4181ff 0.04%, #806bff 72.91%);
  background-size: 200% auto;
  animation: ${highlight} 2s infinite alternate;
`;

const APP_DOWNLOAD_BOTTOM_SHEET_DATA = [
  {
    imageUrl: "https://images.onthelook.co.kr/app/ico_webtoapp_1.png",
    titleLine1: "webtoapp_bottomsheet_msg1",
  },
  {
    imageUrl: "https://images.onthelook.co.kr/app/ico_webtoapp_2.png",
    titleLine1: "webtoapp_bottomsheet_msg2",
  },
  {
    imageUrl: "https://images.onthelook.co.kr/app/ico_webtoapp_3.png",
    titleLine1: "webtoapp_bottomsheet_msg3",
  },
];

const AppDownloadBottomSheet = ({ closeSheet }: { closeSheet: () => void }) => {
  const { track } = useAmplitude();
  const { platform } = useUserAgent();
  const { t } = useTranslation();
  const desktopView = window.innerWidth > 743;

  useEffect(() => {
    const timer = setTimeout(() => {
      window.airbridge?.setDeeplinks({
        buttonID: "app_download_bottom_sheet",
        deeplinks: {
          ios: "otl://onthelook.co.kr",
          android: "otl://onthelook.co.kr",
          desktop: "https://abr.ge/xd6lti",
        },
        fallbacks: {
          ios: "itunes-appstore",
          android: "google-play",
        },
        defaultParams: {
          campaign: "bottom_BT",
          source: "organic",
        },
        desktopPopUp: true,
      });
    }, 500);

    return () => clearTimeout(timer);
  }, []);
  return (
    <Container>
      <MainWrapper>
        <Title>
          <Trans i18nKey="webtoapp_bottomsheet_title" />
        </Title>
        <BlockWrap>
          {APP_DOWNLOAD_BOTTOM_SHEET_DATA.map((item) => (
            <Block key={item.imageUrl}>
              <BlockImg src={item.imageUrl} />
              <SubTitle>
                <Trans
                  i18nKey={
                    desktopView ? `${item.titleLine1}_743` : item.titleLine1
                  }
                />
              </SubTitle>
            </Block>
          ))}
        </BlockWrap>
      </MainWrapper>
      <ButtonWrapper>
        <NextButton onClick={closeSheet}>
          {t("webtoapp_bottomsheet_btn1")}
        </NextButton>
        <DownloadButton
          id="app_download_bottom_sheet"
          onClick={() => {
            track("click app download", {
              os: platform,
              where: "app_download_bottom_sheet",
            });
          }}
        >
          {t("webtoapp_bottomsheet_btn2")}
        </DownloadButton>
      </ButtonWrapper>
    </Container>
  );
};

export default AppDownloadBottomSheet;
