import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import SortIcon from "../../images/icons/ic-sorting.svg";

import { maxWidth } from "core/styles/mixin";

const More = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 2px;
`;

const ItemOrderWrap = styled.div`
  padding-left: 16px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const OrderName = styled.div`
  font-size: 15px;
  line-height: 1.2;
  font-weight: 400;
  color: #000;
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  ${maxWidth}
  height: 100vh;
  background-color: #000;
  z-index: 9;
  opacity: 0.3;
`;

const BottomModalWrap = styled.ul`
  width: 100%;
  ${maxWidth}
  padding: 16px 0 16px 24px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  background-color: white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;

const BottomModalTitle = styled.p`
  font-size: 13px;
  font-weight: bold;
  color: #bbb;
  height: 56px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const BottomModalItem = styled.li<{ active?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  height: 56px;
  color: #333;
  cursor: pointer;
  ${({ active }) => active && "font-weight: bold"};
`;

const ItemOrderSelector = ({ sortField, sortOrder, setOrderFn }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const trans = useTranslation();
  let sortName = trans.t("sort_by_newest");

  if (sortField === "id" && sortOrder === "DESC") {
    sortName = trans.t("sort_by_newest");
  } else if (sortField === "score" && sortOrder === "DESC") {
    sortName = trans.t("sort_by_popularity");
  } else if (sortField === "price" && sortOrder === "ASC") {
    sortName = trans.t("sort_by_price_low_to_high");
  } else if (sortField === "price" && sortOrder === "DESC") {
    sortName = trans.t("sort_by_price_high_to_low");
  } else if (sortField === "salePercentage" && sortOrder === "DESC") {
    sortName = trans.t("sort_by_sale_high_to_low");
  }

  return (
    <React.Fragment>
      <ItemOrderWrap onClick={() => setIsModalOpen(true)}>
        <More src={SortIcon} alt="open sorting options" />
        <OrderName> {sortName}</OrderName>
      </ItemOrderWrap>
      {isModalOpen && (
        <React.Fragment>
          <Background
            onClick={() => {
              setIsModalOpen(false);
            }}
          />
          <BottomModalWrap>
            <BottomModalTitle>{trans.t("sort")}</BottomModalTitle>
            <BottomModalItem
              onClick={() => {
                setIsModalOpen(false);
                setOrderFn({
                  orderName: trans.t("sort_by_newest"),
                  sortField: "id",
                  sortOrder: "DESC",
                });
              }}
              active={sortName === trans.t("sort_by_newest")}
            >
              {trans.t("sort_by_newest")}
            </BottomModalItem>
            <BottomModalItem
              onClick={() => {
                setIsModalOpen(false);
                setOrderFn({
                  orderName: trans.t("sort_by_popularity"),
                  sortField: "score",
                  sortOrder: "DESC",
                });
              }}
              active={sortName === trans.t("sort_by_popularity")}
            >
              {trans.t("sort_by_popularity")}
            </BottomModalItem>
            <BottomModalItem
              onClick={() => {
                setIsModalOpen(false);
                setOrderFn({
                  orderName: trans.t("sort_by_price_low_to_high"),
                  sortField: "price",
                  sortOrder: "ASC",
                });
              }}
              active={sortName === trans.t("sort_by_price_low_to_high")}
            >
              {trans.t("sort_by_price_low_to_high")}
            </BottomModalItem>
            <BottomModalItem
              onClick={() => {
                setIsModalOpen(false);
                setOrderFn({
                  orderName: trans.t("sort_by_price_high_to_low"),
                  sortField: "price",
                  sortOrder: "DESC",
                });
              }}
              active={sortName === trans.t("sort_by_price_high_to_low")}
            >
              {trans.t("sort_by_price_high_to_low")}
            </BottomModalItem>
          </BottomModalWrap>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ItemOrderSelector;
