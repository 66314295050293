import React, { Children, isValidElement } from "react";
import styled from "styled-components";

import BottomNav from "./bottom-navigation";
import Footer from "./footer";
import Header from "./header";
import Main from "./main";

interface LayoutProps {
  children: React.ReactNode;
}
const HeaderType = (<Header />).type;
const FooterType = (<Footer />).type;
const MainType = (<Main />).type;
const BottomNavType = (<BottomNav />).type;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getElement = (children: React.ReactNode, nodeType: any) => {
  const childrenArray = Children.toArray(children);
  return childrenArray.filter(
    (child) => isValidElement(child) && child.type === nodeType
  );
};

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Layout = ({ children }: LayoutProps) => {
  const header = getElement(children, HeaderType);
  const main = getElement(children, MainType);
  const footer = getElement(children, FooterType);
  const bottomNav = getElement(children, BottomNavType);
  return (
    <Wrapper>
      {header}
      {main}
      {bottomNav}
      {footer}
    </Wrapper>
  );
};

export default Object.assign(Layout, { Header, Footer, Main, BottomNav });
