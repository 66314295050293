import { useState, useEffect, useCallback } from "react";

const useScrollToTop = () => {
  const [scrollToTop, setScrollToTop] = useState(false);

  useEffect(() => {
    if (scrollToTop) {
      window.scrollTo(0, 0);
      setScrollToTop(false);
    }
  }, [scrollToTop]);

  const handleScrollToTop = useCallback(() => {
    setScrollToTop(true);
  }, []);

  return { handleScrollToTop };
};

export default useScrollToTop;
