import { lazy } from "react";

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const lazyRetry = (componentImport: any, name: string) =>
  lazy(async () => {
    const pageAlreadyRefreshed = JSON.parse(
      window.sessionStorage.getItem(`retry-${name}-refreshed`) || "false"
    );
    try {
      const component = await componentImport();
      window.sessionStorage.setItem(`retry-${name}-refreshed`, "false");
      return component;
    } catch (error) {
      if (!pageAlreadyRefreshed) {
        window.sessionStorage.setItem(`retry-${name}-refreshed`, "true");
        window.location.reload();
        // IMPORTANT: reload가 되기 전에 return을 하는 것을 막습니다.
        await sleep(1000);
        return;
      }
      throw error;
    }
  });
