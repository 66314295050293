import { Outlet, useLocation } from "react-router-dom";
import React, { Suspense, useEffect } from "react";
import styled from "styled-components";
import { Flip, ToastContainer } from "react-toastify";

import useMediaQuery from "core/screen/use-media-query";
import { maxWidth } from "core/styles/mixin";
import FlashLoadingForFullScreen from "Components/Common/FlashLoadingForFullScreen";
import AppDownloadButtonWrapper from "elements/layout/app-download-button";
import { useAppDownLoad } from "core/context/app-download-provider";
import BottomSheet from "elements/bottom-sheet/bottom-sheet";
import AppDownloadBottomSheet from "elements/layout/app-download-bottom-sheet";

const SectionWrap = styled.div`
  height: 100%;
  width: 100%;
  margin: 0 auto;
  ${maxWidth}
`;

const PcOutlineWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  ${maxWidth}
  margin: 0 auto;
  border-left: 1px solid #f4f4f4;
  border-right: 1px solid #f4f4f4;
  z-index: 101;
  pointer-events: none;
`;

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const Root = () => {
  const isMobileView = useMediaQuery("(max-width: 743px)");
  const { isExposeAppDownload, isOpen, closeSheet } = useAppDownLoad();

  return (
    <>
      <SectionWrap>
        <ScrollToTop />
        <Suspense fallback={<FlashLoadingForFullScreen />}>
          <Outlet />
        </Suspense>
      </SectionWrap>
      {!isMobileView && <PcOutlineWrap />}
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={true}
        transition={Flip}
        closeButton={false}
        limit={1}
      />
      {isExposeAppDownload && (
        <>
          <AppDownloadButtonWrapper />
          <BottomSheet isOpen={isOpen} onClose={closeSheet}>
            <AppDownloadBottomSheet closeSheet={closeSheet} />
          </BottomSheet>
        </>
      )}
    </>
  );
};

export default Root;
