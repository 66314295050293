import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";

import useUserAgent from "core/user-agent/use-user-agent";
import { getWebStorage } from "core/storage";
import { sendMessageToNative } from "core/channels/bridge-handler";

export type SupportLanguage = "korean" | "japanese" | "english";

const LanguageSet: Record<string, SupportLanguage> = {
  ko: "korean",
  "ko-KR": "korean",
  ja: "japanese",
  "ja-JP": "japanese",
  en: "english",
};

const useLangauge = () => {
  const { i18n } = useTranslation();
  const { appVersion } = useUserAgent();
  const storage = getWebStorage();

  const isStackSupportApp = useMemo(
    () => appVersion && Number(appVersion.split(".")[0]) >= 2,
    [appVersion]
  );
  const language = i18n.language;

  const setLanguage = useCallback(
    (lang: string) => {
      if (isStackSupportApp) {
        sendMessageToNative("LANGUAGE", {
          method: "setLanguage",
          params: { language: lang },
        });
      }
      storage.setItem("lang", lang);
    },
    [isStackSupportApp, storage]
  );

  return {
    language: LanguageSet[language] || "english",
    i18nLanguage: language,
    setLanguage,
  };
};

export default useLangauge;
