import React from "react";
import { Outlet, Navigate } from "react-router-dom";

import { useAuth } from "core/context/auth-provider";

const PublicOnlyRoute = () => {
  const { loading, isLoggedIn } = useAuth();

  if (loading) {
    return null;
  }

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export default PublicOnlyRoute;
