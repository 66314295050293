import React from "react";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

import Page404 from "Components/Common/Page404";
import ErrorView from "Components/ErrorView";

function RootBoundary() {
  const error = useRouteError();
  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <Page404 />;
    }
  }
  return <ErrorView />;
}

export { RootBoundary };
