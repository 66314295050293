import React from "react";

import Alert from "Components/alert";
import { useStackNavigate } from "core/navigator";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Component = (error: any) => {
  const navigate = useStackNavigate();
  if (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
  return (
    <Alert
      isOpen={true}
      title={"불편을 드려 죄송해요."}
      message={
        "서비스에 문제가 발생해 \\n빠르게 수정중이니, \\n조금만 더 기다려주세요🙏"
      }
      buttonText="새로고침"
      onClickButton={() => {
        navigate("/", { type: "reset" });
      }}
      onClickBackDrop={() => {
        setTimeout(() => {
          navigate("/", { type: "reset" });
        }, 1000);
      }}
    />
  );
};

export default Component;
