import React from "react";
import { useTranslation } from "react-i18next";
import styled, { keyframes } from "styled-components";

const shimmer = keyframes`
  0% {
    background-position: -300px 0;
  }

  100% {
    background-position: 300px 0;
  }
`;

const PostWrap = styled.div``;

const SekeletonWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const Title = styled.h1`
  font-size: 15px;
  font-weight: 700;
  line-height: 1.2;
  margin: 24px 12px 12px 0;
`;

const PostThumbnail = styled.div`
  width: 270px;
  height: 324px;
  object-fit: cover;
  border-radius: 8px;
  background-color: #f4f4f4;
`;

const ProductLayer = styled.div`
  width: 100%;
  margin-top: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;
`;

const ProductImageWrap = styled.span`
  width: 52px;
  height: 52px;
  margin-right: 8px;
  border-radius: 8px;
  position: relative;
  background: linear-gradient(90deg, #f4f4f4 25%, #e0e0e0 50%, #f4f4f4 75%);
  background-size: 300px 100%;
  animation: ${shimmer} 2.5s infinite linear;
`;

const ProductImage = styled.span`
  width: 52px;
  height: 52px;
  object-fit: contain;
`;

const ProductInfo = styled.span`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: calc(100% - 60px);
`;

const BrandNameWrap = styled.span`
  display: flex;
  align-items: center;
`;

const BrandName = styled.p`
  width: 70px;
  height: 12px;
  background: linear-gradient(90deg, #f4f4f4 25%, #e0e0e0 50%, #f4f4f4 75%);
  background-size: 300px 100%;
  animation: ${shimmer} 3s infinite linear;
`;

const ProductName = styled.p`
  width: 160px;
  height: 12px;
  background: linear-gradient(90deg, #f4f4f4 25%, #e0e0e0 50%, #f4f4f4 75%);
  background-size: 300px 100%;
  animation: ${shimmer} 2.5s infinite linear;
  margin: 4px 0;
`;

const ProductPrice = styled.p`
  width: 140px;
  height: 12px;
  background: linear-gradient(90deg, #f4f4f4 25%, #e0e0e0 50%, #f4f4f4 75%);
  background-size: 300px 100%;
  animation: ${shimmer} 2.8s infinite linear;
`;

const BrandCreatorPostSkeletonElement = () => {
  return (
    <PostWrap>
      <PostThumbnail />
      <ProductLayer>
        <ProductImageWrap>
          <ProductImage />
        </ProductImageWrap>
        <ProductInfo>
          <BrandNameWrap>
            <BrandName />
          </BrandNameWrap>
          <ProductName />
          <ProductPrice />
        </ProductInfo>
      </ProductLayer>
    </PostWrap>
  );
};

const BrandCreatorPostSkeleton = ({ amount }: { amount: number }) => {
  const { t } = useTranslation();
  return (
    <SekeletonWrap>
      <Title>{t("brand_creatorstyle_title")}</Title>
      <FlexWrap>
        {[...Array(amount).keys()].map((index) => (
          <BrandCreatorPostSkeletonElement key={index} />
        ))}
      </FlexWrap>
    </SekeletonWrap>
  );
};

export default BrandCreatorPostSkeleton;
