import React, { createContext, useEffect } from "react";

import { useAuth } from "../auth-provider";

import useUserAgent from "core/user-agent/use-user-agent";
import { getWebStorage } from "core/storage";

interface AirbridgeProviderProps {
  children: React.ReactNode;
  token: string;
}

const AirbridgeContext = createContext<Record<string, unknown> | undefined>(
  undefined
);

function getInitScript({
  appName,
  webToken,
  user,
}: {
  appName: string;
  webToken: string;
  user: string | undefined;
}) {
  return `
  (function(a_,i_,r_,_b,_r,_i,_d,_g,_e){if(!a_[_b]){var d={queue:[]};_r.concat(_i).forEach(function(a){var i_=a.split("."),a_=i_.pop();i_.reduce(function(a,i_){return a[i_]=a[i_]||{}},d)[a_]=function(){d.queue.push([a,arguments])}});a_[_b]=d;a_=i_.getElementsByTagName(r_)[0];i_=i_.createElement(r_);i_.onerror=function(){d.queue.filter(function(a){return 0<=_i.indexOf(a[0])}).forEach(function(a){a=a[1];a=a[a.length-1];"function"===typeof a&&a("error occur when load airbridge")})};i_.defer=1;i_.src="//static.airbridge.io/sdk/latest/airbridge.min.js";a_.parentNode.insertBefore(i_,a_)}})(window,document,"script","airbridge","init fetchResource setBanner setDownload setDownloads openDeeplink setDeeplinks sendWeb setUserAgent setUserAlias addUserAlias setMobileAppData setUserId setUserEmail setUserPhone setUserAttributes clearUser setDeviceIFV setDeviceIFA setDeviceGAID events.send events.signIn events.signUp events.signOut events.purchased events.addedToCart events.productDetailsViewEvent events.homeViewEvent events.productListViewEvent events.searchResultViewEvent".split(" "),["events.wait"]);
  airbridge.init({
      app: '${appName}',
      webToken: '${webToken}',
      user: ${user},
      utmParsing: true,
      useProtectedAttributionWindow: true
  });
  `;
}

const initAirbridge = (innerHTML: string) => {
  const head = document.querySelector("head");
  const script = document.createElement("script");
  script.defer = true;
  if (innerHTML) script.innerHTML = innerHTML;
  head?.appendChild(script);
};

const AirbridgeProvider = ({ children, token }: AirbridgeProviderProps) => {
  const { isWeb, isSearchEngineBot } = useUserAgent();
  const { user } = useAuth();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (isWeb && !window.airbridge?.init && !isSearchEngineBot) {
      const userInfo = {
        externalUserID: user?.id,
        alias: {
          amplitude_device_id: getWebStorage().getItem("amplitudeId"),
        },
      };

      initAirbridge(
        getInitScript({
          appName: "onthelook",
          webToken: token,
          user: JSON.stringify(userInfo),
        })
      );
    }
  }, [isSearchEngineBot, isWeb, token, user]);

  return (
    <AirbridgeContext.Provider value={undefined}>
      {children}
    </AirbridgeContext.Provider>
  );
};

export default AirbridgeProvider;
