// BottomSheet.tsx
import React from "react";
import Sheet from "react-modal-sheet";
import styled from "styled-components";

interface BottomSheetProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const StyledSheet = styled(Sheet)`
  .react-modal-sheet-header {
    height: 20px !important;
  }
`;

const BottomSheet: React.FC<BottomSheetProps> = ({
  isOpen,
  onClose,
  children,
}) => {
  return (
    <StyledSheet
      isOpen={isOpen}
      onClose={onClose}
      detent="content-height"
      className="custom-bottom-sheet"
    >
      <Sheet.Container
        style={{
          borderTopRightRadius: "20px",
          borderTopLeftRadius: "20px",
          maxWidth: "743px",
          margin: "auto",
        }}
      >
        <Sheet.Header />
        <Sheet.Content>{children}</Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop
        onTap={onClose}
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.7)",
        }}
      />
    </StyledSheet>
  );
};

export default BottomSheet;
