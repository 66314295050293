import React, { createContext, useEffect } from "react";

import useUserAgent from "core/user-agent/use-user-agent";

interface AppDownLoadProviderProps {
  children: React.ReactNode;
}

interface AppDownLoadContextProps {
  isExposeAppDownload: boolean;
  isOpen: boolean;
  openSheet: () => void;
  closeSheet: () => void;
}

const AppDownLoadContext = createContext<undefined | AppDownLoadContextProps>(
  undefined
);

const AppDownLoadProvider = ({ children }: AppDownLoadProviderProps) => {
  const [bottomSheetOpen, setBottomSheetOpen] = React.useState(false);
  const [isExposeAppDownload, setIsExposeAppDownload] = React.useState(false);
  const { isApp, isAppModeOnWeb } = useUserAgent();

  const openSheet = () => {
    setBottomSheetOpen(true);
  };

  const closeSheet = () => {
    setBottomSheetOpen(false);
  };

  useEffect(() => {
    setTimeout(() => {
      const isShopLink = window.location.pathname.includes("/s/");
      const isLoginPage = window.location.pathname.includes("/login");
      if (!isApp && !isAppModeOnWeb && !isShopLink && !isLoginPage) {
        setIsExposeAppDownload(true);
      }
    }, 1200);
  }, [isApp, isAppModeOnWeb]);

  return (
    <AppDownLoadContext.Provider
      value={{
        isExposeAppDownload,
        isOpen: bottomSheetOpen,
        openSheet,
        closeSheet,
      }}
    >
      {children}
    </AppDownLoadContext.Provider>
  );
};

function useAppDownLoad() {
  const context = React.useContext(AppDownLoadContext);
  if (context === undefined) {
    throw new Error("useAppDownLoad must be used within a AmplitudeProvider");
  }
  return context;
}

export { AppDownLoadProvider, useAppDownLoad };
