import { MoodLanguageMap, creatorJobMap } from "./LanguageMap";

export const LANG_JA = "ja";
export const LANG_EN = "en";
export const LANG_KO = "ko";

const getLanguageCode = () => {
  return localStorage.getItem("i18nextLng") || "";
};

/**
 * @deprecated 이 메소드는 더 이상 지원되지 않습니다. useLanguage hook을 사용하세요.
 */
export const isKorean = getLanguageCode().includes(LANG_KO);

/**
 * @deprecated 이 메소드는 더 이상 지원되지 않습니다. useLanguage hook을 사용하세요.
 */
export const isJapan = getLanguageCode().includes(LANG_JA);

/**
 * @deprecated 이 메소드는 더 이상 지원되지 않습니다. useLanguage hook을 사용하세요.
 */
export const isEnglish = getLanguageCode().includes(LANG_EN);

const convertMood = (mood, lng) => {
  const c = MoodLanguageMap[mood];
  if (c) {
    return c[lng];
  }
};

const convertJob = (job, lng) => {
  const c = creatorJobMap[job];
  if (c) {
    return c[lng];
  }
};

export const convertMoodsLng = (creatorMoods, lng) => {
  return creatorMoods.map((m) => convertMood(m, lng));
};

export const convertJobLng = (creatorJob, lng) => {
  return convertJob(creatorJob, lng);
};

export const getByCountry = ({
  ko,
  ja,
  en,
}: {
  ko: string;
  ja: string;
  en: string;
}) => {
  if (isKorean) {
    return ko;
  } else if (isJapan) {
    return ja;
  } else {
    return en;
  }
};
