import React, { createContext, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ApolloError } from "@apollo/client";
import queryString from "query-string";

import {
  useCancelAlarmCreator,
  useCancelSubscription,
  useCancleBookmarkPost,
  useCancleLike,
  useDoAlarmCreator,
  useDoBookmarkPost,
  useDoLike,
  useDoSubscription,
  useGetPost,
  useGetPostEngagement,
  useGetPostItemPoints,
} from "./fetch-hooks";
import PostDetailSkeleton from "./post-detail-skeleton";

import {
  Post,
  PostBookmarkEngagement,
  PostImage,
  PostItem,
  PostItemPoint,
  ProductBookmarkEngagementV2,
  StyleTag,
  User,
} from "types/generated/graphql";
import { useAuth } from "core/context/auth-provider";

interface BottomSheetStateTypes {
  productId: number;
  isOpen: boolean;
}
interface PostDetailProviderProps {
  children: React.ReactNode;
}

interface PostDetailContextProps {
  post: Post;
  loading: boolean;
  error: ApolloError | undefined;
  didLike: boolean;
  didSubs: boolean;
  didSetAlarm: boolean;
  didPostBookmark: boolean;
  productBookmark: ProductBookmarkEngagementV2[];
  likeCount: number;
  totalPostBookmarkCount: number;
  filteredItemPoints: PostItemPoint[];
  postItems: PostItem[];
  isOwnPost: boolean;

  postImages: PostImage[];
  styleTags: StyleTag[];
  creator: User;
  postDetail: string;
  postCreatedAt: number;
  bottomSheetState: BottomSheetStateTypes;
  setBottomSheetState: React.Dispatch<
    React.SetStateAction<BottomSheetStateTypes>
  >;

  doLikePost: () => void;
  cancelLikePost: () => void;
  doBookmark: () => void;
  cancelBookmark: () => void;
  doSubscription: () => void;
  cancelSubscription: () => void;
  doAlarmCreator: () => void;
  cancelAlarmCreator: () => void;
}

const PostDetailContext = createContext<undefined | PostDetailContextProps>(
  undefined
);

const PostDetailProvider = ({ children }: PostDetailProviderProps) => {
  const { id: postId } = useParams() as { id: string };
  const { cache } = queryString.parse(location.search) as { cache: string };
  const { post, loading, error } = useGetPost(postId, cache !== "false");
  const { engagement } = useGetPostEngagement(postId || "");
  const { itemPoints } = useGetPostItemPoints(Number(postId || 0));
  const [bottomSheetState, setBottomSheetState] =
    useState<BottomSheetStateTypes>({
      productId: 0,
      isOpen: false,
    });

  const { user } = useAuth();

  const isOwnPost = user?.id === post?.userId;
  const postItems = post?.postItems as PostItem[];

  const doLikePost = useDoLike({
    postId: Number(postId),
    userId: 1,
    targetUserId: post?.user?.id || 0,
    targetUserInstagramId: post?.user?.instagramId || "",
    targetUserGender: post?.user?.gender || "",
  });

  const cancelLikePost = useCancleLike({
    postId: Number(postId),
  });

  const doBookmark = useDoBookmarkPost({
    targetId: Number(postId),
    refererCreatorId: post?.user?.id || 0,
    refererPostId: Number(postId),
  });

  const cancelBookmark = useCancleBookmarkPost({
    targetId: Number(postId),
  });

  const doSubscription = useDoSubscription({
    targetUserId: post?.user?.id || 0,
  });

  const cancelSubscription = useCancelSubscription({
    targetUserId: post?.user?.id || 0,
  });

  const doAlarmCreator = useDoAlarmCreator({
    targetUserId: post?.user?.id || 0,
  });

  const cancelAlarmCreator = useCancelAlarmCreator({
    targetUserId: post?.user?.id || 0,
  });

  const filteredItemPoints = useMemo(() => {
    return itemPoints?.filter((itemPoint) => {
      return postItems?.some((postItem) => {
        return (
          postItem?.isWear === true &&
          postItem?.category?.split("_")?.[1] === itemPoint?.category
        );
      });
    });
  }, [itemPoints, postItems]);

  if (loading) {
    return <PostDetailSkeleton />;
  }

  const postBookmark = engagement?.postBookmark as PostBookmarkEngagement;
  const productBookmark =
    engagement?.productBookmark as ProductBookmarkEngagementV2[];

  const like = engagement?.like;
  const subs = engagement?.subs;
  const didLike: boolean = like?.is || false;
  const didSubs: boolean = subs?.isSubs || false;
  const didSetAlarm: boolean = subs?.isAlarm || false;
  const didPostBookmark: boolean = postBookmark?.is || false;
  const likeCount = like?.count || 0;
  const totalPostBookmarkCount = postBookmark?.count || 0;

  const postImages = post?.images as PostImage[];
  const postDetail = post?.detail as string;
  const creator = post?.user as User;
  const postCreatedAt = Number(post?.createdAt);
  const styleTags = post?.styleTags as StyleTag[];

  return (
    <PostDetailContext.Provider
      value={{
        post,
        loading,
        error,
        didLike,
        didSubs,
        didSetAlarm,
        didPostBookmark,
        productBookmark,
        likeCount,
        totalPostBookmarkCount,
        filteredItemPoints,
        postItems,
        isOwnPost,
        postImages,
        postDetail,
        creator,
        postCreatedAt,
        styleTags,
        bottomSheetState,
        setBottomSheetState,
        doLikePost,
        cancelLikePost,
        doBookmark,
        cancelBookmark,
        doSubscription,
        cancelSubscription,
        doAlarmCreator,
        cancelAlarmCreator,
      }}
    >
      {children}
    </PostDetailContext.Provider>
  );
};

function usePostDetail() {
  const context = React.useContext(PostDetailContext);
  if (context === undefined) {
    throw new Error("usePostDetail must be used within a PostDetailProvider");
  }
  return context;
}

export { PostDetailProvider, usePostDetail };
