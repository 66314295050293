import { useMemo } from "react";
import { createPortal } from "react-dom";

interface ModalPortalProps {
  children: React.ReactNode;
  elementId?: string;
}

const ModalPortal = ({
  children,
  elementId = "root-modal",
}: ModalPortalProps) => {
  const el = useMemo(() => document.getElementById(elementId), [elementId]);

  if (el) {
    return createPortal(children, el);
  }

  return null;
};

export default ModalPortal;
