import React from "react";
import { Outlet, useParams } from "react-router-dom";
import styled from "styled-components";

import Layout from "elements/layout";
import NavBar from "elements/navbar";
import {
  useGetBrandQuery,
  useNavHeaderQueryQuery,
} from "types/generated/graphql";
import Cart from "elements/icon/cart";
import useScrollToTop from "core/navigator/use-scroll-to-top";
import { useStackNavigate } from "core/navigator";
import { StackLink } from "elements/link";
import { useCommerceUrlGenerator } from "libs/order/hooks/use-commerce-url-generator";
import { useIsTopOfViewport } from "Components/post-detail/utils";
import { useAppDownLoad } from "core/context/app-download-provider";
import { useAuth } from "core/context/auth-provider";

const Title = styled.div<{ hide: boolean }>`
  width: calc(100vw - 120px);
  max-width: calc(743px - 120px);
  min-height: 20px;
  font-size: 18px;
  line-height: 21px;
  font-weight: 700;
  color: #000;
  display: -webkit-box;
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  ${({ hide }) =>
    hide &&
    `
    animation: fadeout 0.3s forwards
  `}

  ${({ hide }) =>
    !hide &&
    `
    animation: fadein 0.3s forwards
  `}

  @keyframes fadeout {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;

const BrandDetailLayout = () => {
  const { handleScrollToTop } = useScrollToTop();
  const { id: brandId } = useParams() as { id: string };
  const stackNavigate = useStackNavigate();
  const { getCartDetailUrl, isOpenOnthelookStore } = useCommerceUrlGenerator();

  const { data: headerResponse } = useNavHeaderQueryQuery();
  const { data: brandResponse } = useGetBrandQuery({
    variables: { id: brandId },
  });

  const isTop = useIsTopOfViewport();

  const pageName = brandResponse?.Brand.items?.name;
  const cartCount = headerResponse?.GetCartCount.count;
  const { isExposeAppDownload, openSheet } = useAppDownLoad();
  const { isLoggedIn } = useAuth();

  return (
    <Layout>
      <Layout.Header>
        <Layout.Header.StartAdorment>
          <NavBar marginLeft="20px">
            <NavBar.Item onClick={() => stackNavigate(-1)} icon="back" />
          </NavBar>
        </Layout.Header.StartAdorment>
        <Layout.Header.CenterAdorment>
          <NavBar.Item>
            <Title onClick={() => handleScrollToTop()} hide={isTop}>
              {pageName}
            </Title>
          </NavBar.Item>
        </Layout.Header.CenterAdorment>
        <Layout.Header.EndAdorment>
          <NavBar marginRight="18px">
            <NavBar.Item>
              {isExposeAppDownload && !isLoggedIn ? (
                <Cart onClick={openSheet} count={cartCount} />
              ) : (
                <StackLink
                  className="cart-icon-wrap"
                  to={getCartDetailUrl()}
                  screenType={
                    isOpenOnthelookStore(true) ? "onthelook-store" : undefined
                  }
                >
                  <Cart onClick={() => {}} count={cartCount} />
                </StackLink>
              )}
            </NavBar.Item>
          </NavBar>
        </Layout.Header.EndAdorment>
      </Layout.Header>
      <Layout.Main>
        <Outlet />
      </Layout.Main>
      <Layout.BottomNav />
    </Layout>
  );
};

export default BrandDetailLayout;
