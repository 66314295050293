import { useThrottle } from "@toss/react";
import { useState, useEffect } from "react";

export function useDidScroll() {
  const [didScroll, setDidScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setDidScroll(true);
    };

    if (!didScroll) {
      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [didScroll]);

  return didScroll;
}

export function useIsTopOfViewport() {
  const [isTop, setIsTop] = useState(true);

  const handleScroll = useThrottle(() => {
    const currentScrollY = window.scrollY;

    if (currentScrollY <= 0) {
      setIsTop(true);
    } else {
      setIsTop(false);
    }
  }, 500);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return isTop;
}
