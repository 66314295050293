import amplitude from "amplitude-js";
import { useCallback, useMemo } from "react";

import packageJson from "../../../package.json";
import { sendMessageToNative } from "../../core/channels/bridge-handler";
import useUserAgent from "../../core/user-agent/use-user-agent";

import {
  sendAmpLogEvent,
  setLoginUserProps,
  setLogoutUserProps,
} from "Functions/AmplitudeUtil";
import { LoginType } from "core/context/auth-provider";

export interface AmplitudeParams {
  [index: string]: unknown;
}

export interface AmplitudeLoginParams {
  userId: number;
  loginType?: LoginType;
}

export interface PageViewParams {
  source: string;
  version: number;
}

const PAGE_VIEW_EVENT_NAME = "Page viewed";

const useAmplitude = () => {
  const { appVersion, isApp } = useUserAgent();
  // TODO: RN이 배포되면 삭제되어야 함

  const isLegacyApp = useMemo(
    () => (appVersion ? appVersion.split(".")[0] === "0" : false),
    [appVersion]
  );

  const trackPageView = useCallback(
    ({ source, version }: PageViewParams) => {
      const formattedParams = {
        Where: source,
        Path: window.location.pathname,
        Version: String(version),
        packageVersion: packageJson.version,
      };
      if (isLegacyApp) {
        sendAmpLogEvent(PAGE_VIEW_EVENT_NAME, formattedParams);
      } else {
        if (isApp) {
          sendMessageToNative("AMPLITUDE", {
            method: "track",
            params: {
              eventName: PAGE_VIEW_EVENT_NAME,
              eventProperties: { ...formattedParams },
            },
          });
        } else {
          // TODO: 해당 라이브러리가 deprecated 됨
          const amp = amplitude.getInstance();
          amp.logEvent(PAGE_VIEW_EVENT_NAME, formattedParams);
        }
      }
    },
    [isApp, isLegacyApp]
  );

  const track = useCallback(
    (eventName: string, params?: AmplitudeParams) => {
      const formattedParams = {
        packageVersion: packageJson.version,
        ...params,
      };

      if (isLegacyApp) {
        sendAmpLogEvent(eventName, formattedParams);
      } else {
        if (isApp) {
          sendMessageToNative("AMPLITUDE", {
            method: "track",
            params: {
              eventName,
              eventProperties: { ...formattedParams },
            },
          });
        } else {
          // TODO: 해당 라이브러리가 deprecated 됨
          const amp = amplitude.getInstance();
          amp.logEvent(eventName, params);
        }
      }
    },
    [isApp, isLegacyApp]
  );

  const login = useCallback(
    (params: AmplitudeLoginParams) => {
      if (isLegacyApp) {
        setLoginUserProps(params);
      } else {
        if (isApp) {
          sendMessageToNative("AMPLITUDE", {
            method: "setUserId",
            params: {
              eventProperties: {
                ...params,
              },
            },
          });
        } else {
          const amp = amplitude.getInstance();
          amp.setUserId(String(params.userId));
        }
      }
    },
    [isApp, isLegacyApp]
  );

  const logout = useCallback(() => {
    if (isLegacyApp) {
      setLogoutUserProps();
    } else {
      if (isApp) {
        sendMessageToNative("AMPLITUDE", {
          method: "reset",
        });
      } else {
        const amp = amplitude.getInstance();
        amp.setUserId(null);
        amp.regenerateDeviceId();
      }
    }
  }, [isApp, isLegacyApp]);

  const updateUser = useCallback((params: AmplitudeParams) => {
    sendMessageToNative("AMPLITUDE", {
      method: "identify",
      params: {
        eventProperties: {
          packageVersion: packageJson.version,
          ...params,
        },
      },
    });
  }, []);

  return { track, login, logout, updateUser, trackPageView };
};

export { useAmplitude };
