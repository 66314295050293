import { v4 as uuidv4 } from "uuid";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  from,
  ApolloLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/react";
import { RetryLink } from "@apollo/client/link/retry";

import { getWebStorage } from "core/storage";

const cache = new InMemoryCache();
const storage = getWebStorage();

const retryLink = new RetryLink({
  attempts: {
    max: 3, // Maximum number of times to retry
    retryIf: (error) => {
      // Retry if the error is a network error or a GraphQL error with a 5xx status code
      return (
        !!error.networkError ||
        (error.graphQLErrors &&
          error.graphQLErrors.some(
            ({ extensions }) => extensions?.response?.status >= 500
          ))
      );
    },
  },
});

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      Sentry.captureException(
        new Error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      )
    );
  // eslint-disable-next-line no-console
  if (networkError)
    Sentry.captureException(new Error(`[Network error]: ${networkError}`));
});

const httpLink = createHttpLink({
  uri: import.meta.env.VITE_API_ENDPOINT,
});

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => {
    if (!storage.getItem("otlUUID")) {
      storage.setItem("otlUUID", uuidv4());
    }
    return {
      headers: {
        ...headers,
        "A-KEY": storage.getItem("aKey") || "",
        FCM: storage.getItem("fcm") || "",
        apdId: storage.getItem("amplitudeId") || "",
        "X-JWT": storage.getItem("authToken") || "",
        gcid: storage.getItem("gcid") || "",
        uuid: storage.getItem("otlUUID") || "",
        lang: storage.getItem("lang") || "ko",
        ut_g: storage.getItem("gender") || "",
      },
    };
  });
  return forward(operation);
});

const client = new ApolloClient({
  cache,
  // 순서 중요합니다 바꿀 때 주의해주세요.
  link: from([authLink, retryLink, httpLink, errorLink]),
});

export default client;
