import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import ModalPortal from "elements/modal/modal-portal";
import { useNativeEvent } from "core/channels/use-native-event";
import { getReadableMessage } from "Functions/TextUtil";
import { useStackNavigate } from "core/navigator";

const StyledPopup = styled(Popup)`
  &-overlay {
    z-index: 1001;

    @media (min-width: 1080px) {
      left: 0;
      right: 0;
      width: 100%;
      margin: 0 auto;
    }
  }

  &-content {
    width: 300px;
    max-width: 600px;
    border-radius: 6px;
    padding: 0;
    border: 1px solid white;
  }
`;

const CartPopup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CartPopupTitle = styled.div`
  padding: 32px 16px;
  font-size: 15px;
  color: #111;
  text-align: center;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Button = styled.div`
  border-top: 1px solid #eee;
  font-size: 16px;
  height: 49px;
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  cursor: pointer;
`;

interface IProps {
  isOpen?: boolean;
  closeFn?: () => void;
  message?: string;
  cancelButtonText?: string;
}

const hasHistoryToback = window.history.length > 1;

const LoginDialog: React.FunctionComponent<IProps> = ({
  closeFn,
  message,
  cancelButtonText,
}) => {
  const { vibrate } = useNativeEvent();
  const navigate = useStackNavigate();
  const popupRef = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    if (closeFn) {
      closeFn();
    } else {
      navigate(-1);
    }
  };

  // Document Click 핸들러가 없어, Popup 내부에 Div를 만들어서 클릭 이벤트를 처리합니다.
  const handleDocumentClick = (e: MouseEvent) => {
    if (popupRef.current && !popupRef.current.contains(e.target as Node)) {
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleDocumentClick);
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, []);

  const trans = useTranslation();

  return (
    <ModalPortal>
      <StyledPopup
        open={true}
        onClose={handleClose}
        // 스택 내비게이션에서 history가 없는 경우, 뒤로가기를 눌렀을 때 팝업 상태를 유지하도록 합니다.
        closeOnDocumentClick={hasHistoryToback}
      >
        <CartPopup ref={popupRef}>
          <CartPopupTitle>
            {getReadableMessage({ text: message || trans.t("need_login") })}
          </CartPopupTitle>
          <ButtonWrap>
            <Button
              onClick={() => {
                vibrate();
                handleClose();
              }}
              style={{ borderRight: "1px solid #eee" }}
            >
              {cancelButtonText || trans.t("go_back")}
            </Button>
            <Link
              to={`/login?callbackUrl=${window.location.href}`}
              replace={true}
            >
              <Button
                onClick={() => {
                  vibrate();
                }}
                style={{ color: "#ff2c58" }}
              >
                {trans.t("do_login")}
              </Button>
            </Link>
          </ButtonWrap>
        </CartPopup>
      </StyledPopup>
    </ModalPortal>
  );
};

export default LoginDialog;
