import queryString from "query-string";
import React, { createContext, useCallback, useEffect, useState } from "react";

import { GREY_IMAGE } from "core/image/skelton";

export type ResizableImageWidth =
  | 128
  | 192
  | 256
  | 420
  | 600
  | 1000
  | 1200
  | 1400;

type Quality = "LOW" | "MID" | "HIGH";
interface ResizeImageProviderProps {
  children: React.ReactNode;
}

interface ConvertedImageUrlParams {
  imageUrl: string;
  width?: ResizableImageWidth;
  height?: number;
  quality?: Quality | number;
  format?: "png" | "jpeg" | "gif" | "jpg";
}

interface ResizeImageContextProps {
  getConvertedImageUrl: (params: ConvertedImageUrlParams) => string;
}

const ResizeImageContext = createContext<ResizeImageContextProps | undefined>(
  undefined
);

const IMAGE_QUALITY: Record<Quality, number> = {
  LOW: 60,
  MID: 80,
  HIGH: 85,
};

const isValidImageUrl = (originUrl: string) =>
  originUrl?.includes("https://images.onthelook.co.kr") ||
  originUrl?.includes("https://images-dev.onthelook.co.kr");

const paramsOrder = ["w", "h", "q", "f"];

const ResizeImageProvider = ({ children }: ResizeImageProviderProps) => {
  const [isSupportWebp, setIsSupportWebp] = useState(false);

  useEffect(() => {
    async function supportsWebp() {
      if (!self.createImageBitmap) return false;

      const webpData =
        "data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=";
      const response = await fetch(webpData);
      const blob = await response.blob();
      try {
        await createImageBitmap(blob);
        setIsSupportWebp(true);
      } catch {
        setIsSupportWebp(false);
      }
    }
    supportsWebp();
  });

  const getConvertedImageUrl = useCallback(
    ({
      imageUrl = GREY_IMAGE,
      width,
      height,
      quality,
      format,
    }: ConvertedImageUrlParams) => {
      if (!isValidImageUrl(imageUrl)) {
        return imageUrl;
      }

      const query = queryString.stringify(
        {
          w: width,
          h: height,
          q:
            quality && typeof quality === "string"
              ? IMAGE_QUALITY[quality]
              : quality,
          f: format || isSupportWebp ? "webp" : undefined,
        },
        {
          skipNull: true,
          // w, h, q, f 순으로 정렬 시킵니다.
          sort: (a, b) => paramsOrder.indexOf(a) - paramsOrder.indexOf(b),
        }
      );
      return `${imageUrl}?${query}`;
    },
    [isSupportWebp]
  );

  return (
    <ResizeImageContext.Provider value={{ getConvertedImageUrl }}>
      {children}
    </ResizeImageContext.Provider>
  );
};

function useResizeImage() {
  const context = React.useContext(ResizeImageContext);
  if (context === undefined) {
    throw new Error("useResizeImage must be used within a AmplitudeProvider");
  }
  return context;
}

export { ResizeImageProvider, useResizeImage };
