import {
  osName,
  isIOS,
  isAndroid,
  isTablet,
  getUA as userAgent,
} from "react-device-detect";

import { getWebStorage } from "core/storage";
import i18n from "locale/i18n";

export enum PlatformTypes {
  IOS = "IOS APP",
  ANDROID = "ANDROID APP",
  WEB = "WEB",
}

export const isProduction = (): boolean => {
  return import.meta.env.VITE_API_PROFILE === "production";
};

export const isDevelopment = (): boolean => {
  return import.meta.env.VITE_API_PROFILE === "development";
};

export const isStage = (): boolean => {
  return import.meta.env.VITE_API_PROFILE === "stage";
};

const semverPattern = /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)$/;

function getAppVersion(ua: string): string | null {
  // ReactNative webview에서 app version을 삽입합니다.
  if (
    window.ReactNativeAppVersion &&
    window.ReactNativeAppVersion !== "0.0.0" &&
    semverPattern.test(window.ReactNativeAppVersion)
  ) {
    return window.ReactNativeAppVersion;
  }
  const regx = /(otl_app_android|otl_app_ios)\/(\d+).(\d+).(\d+)/;
  const match = ua.match(regx);
  if (match && match[0]) {
    // x.x.x 형태의 값을 return 합니다.
    return match[0].split("/")[1];
  }
  return null;
}

function getPlatform(os: string) {
  if (os === "iOS") {
    return PlatformTypes.IOS;
  } else if (os === "Android") {
    return PlatformTypes.ANDROID;
  } else {
    return PlatformTypes.WEB;
  }
}

const isVersionGreaterOrEqual = ({
  version,
  targetVersion,
}: {
  version: string;
  targetVersion: string;
}) => {
  if (!version || !targetVersion) return false;

  const versionParts = version.split(".").map((part) => parseInt(part));
  const targetVersionParts = targetVersion
    .split(".")
    .map((part) => parseInt(part));

  // 비교 대상 버전 정보가 더 짧은 경우, 남은 부분을 0으로 채움
  while (versionParts.length < targetVersionParts.length) {
    versionParts.push(0);
  }

  // 버전 정보를 순서대로 비교
  for (const i in targetVersionParts) {
    if (versionParts[i] > targetVersionParts[i]) {
      return true;
    } else if (versionParts[i] < targetVersionParts[i]) {
      return false;
    }
  }

  return true;
};

function isSupportedKakaoSDKV2(appVersion: string | null) {
  // web: 조건없이 지원 가능
  if (!appVersion) return true;
  // app : 1.0.xx는 kakao SDKV2 지원안됨, 1.1 이상부터 가능
  return isVersionGreaterOrEqual({
    version: appVersion,
    targetVersion: "1.1.0",
  });
}

function isSupportedFacebookLogin(appVersion: string | null) {
  // web: 웹 지원가능하나 로직상 분기처리 필요. 다른방식으로 로그인
  if (!appVersion) return true;
  return isVersionGreaterOrEqual({
    version: appVersion,
    targetVersion: "2.6.4",
  });
}

function isSupportedGlobalScreen(appVersion: string | null) {
  // web: 조건없이 지원 가능
  if (!appVersion) return true;
  // app : 1.0.xx는 kakao SDKV2 지원안됨, 1.1 이상부터 가능
  return isVersionGreaterOrEqual({
    version: appVersion,
    targetVersion: "2.2.0",
  });
}

function isSupportedNativePageEvent(appVersion: string | null) {
  // web: 조건없이 지원 가능
  if (!appVersion) return true;
  // app : 1.0.xx는 kakao SDKV2 지원안됨, 1.1 이상부터 가능
  return isVersionGreaterOrEqual({
    version: appVersion,
    targetVersion: "2.3.0",
  });
}

function isSupportedAutoLineLogin(appVersion: string | null) {
  // web & ios: 조건없이 지원 가능
  if (!isAndroid || !appVersion) return true;
  // 안드로이드 1.1.4 부터 가능
  return isVersionGreaterOrEqual({
    version: appVersion,
    targetVersion: "1.1.4",
  });
}

function isSupportedOnthelookStore(appVersion: string | null) {
  // web에서도 카페24 열리도록한다.
  if (!appVersion) return true;
  return isVersionGreaterOrEqual({
    version: appVersion,
    targetVersion: "2.5.1",
  });
}

function isSupportedNativeShare(appVersion: string | null) {
  // web: 사용 불가능
  if (!appVersion) return false;
  // 2.5.0부터 네이티브 공유를 사용합니다.
  return isVersionGreaterOrEqual({
    version: appVersion,
    targetVersion: "2.5.0",
  });
}

function isSupportedNativeUserPage(appVersion: string | null) {
  const language = i18n.language;
  // web: 사용 불가능
  if (!appVersion) return false;
  // 2.5.5부터 네이티브 유저 스크린을 지원합니다.
  else if (!(language === "ko" || language === "ko-KR")) {
    return false;
  }
  // 한국어만 사용 가능합니다.

  return isVersionGreaterOrEqual({
    version: appVersion,
    targetVersion: "2.5.5",
  });
}

function isSupportedShorts(appVersion: string | null) {
  if (isTablet) {
    return false;
  }
  if (isDevelopment()) {
    return true;
  }
  // web: 사용 불가능
  if (!appVersion) return false;
  // 2.6.1부터 쇼츠를 사용합니다.
  return isVersionGreaterOrEqual({
    version: appVersion,
    targetVersion: "2.6.1",
  });
}

function isSupportedUserInfo(appVersion: string | null) {
  if (isDevelopment()) {
    return true;
  }
  // web: 사용 불가능
  if (!appVersion) return false;
  // 2.7.0부터 사용합니다.
  return isVersionGreaterOrEqual({
    version: appVersion,
    targetVersion: "2.8.0",
  });
}

function isSupportedKakaoOpen(appVersion: string | null) {
  // web: 사용 불가능
  if (!appVersion) return false;
  // 2.6.3부터 kakaoopen schema를 사용합니다.
  return isVersionGreaterOrEqual({
    version: appVersion,
    targetVersion: "2.6.3",
  });
}

export const isV2App = (): boolean => {
  const appVersion = getAppVersion(userAgent);
  const isApp = !!appVersion;
  return (
    isApp &&
    isVersionGreaterOrEqual({ version: appVersion, targetVersion: "2.0.0" })
  );
};

export function isSearchEngineBot(): boolean {
  if (window.ReactNativeAppVersion) {
    return false;
  }
  const regx =
    /(Googlebot|GoogleOther|Lighthouse|InspectionTool|Storebot|Yeti|kakaotalk-scrap|facebookexternalhit|Slackbot-LinkExpanding|Twitterbot|Daum|GPTBot|bingbot|DuckDuckBot|archive.org_bot)/i;
  const match = window.navigator.userAgent.match(regx);
  return !!match;
}

export function needForceUpdate(): boolean {
  const appVersion = getAppVersion(userAgent);
  const MIN_IOS_VERSION = "2.5.1";
  const MIN_ANDROID_VERSION = "2.5.1";

  if (!appVersion) return false;
  if (isAndroid) {
    return !isVersionGreaterOrEqual({
      version: appVersion,
      targetVersion: MIN_ANDROID_VERSION,
    });
  }

  if (isIOS) {
    return !isVersionGreaterOrEqual({
      version: appVersion,
      targetVersion: MIN_IOS_VERSION,
    });
  }

  return false;
}

/*
 * IMPORTANT
 * React Native에서 ReactNativeAppVersion를 inject 하는 타이밍에
 * 따라 해당 값이 undefined일 수 있으며 안드로이드에서 해당 현상이 두드러집니다.
 * 따라서 React Native에서 js inject 시점에, CustomEvent를 발생시켜
 * Webview와 React 간의 상태를 동기화 시켜야 합니다.
 */
function useUserAgent() {
  const appVersion = getAppVersion(userAgent);
  const isApp = !!appVersion;
  const buildNumber = window.ReactNativeBuildNumber
    ? `(${window.ReactNativeBuildNumber})`
    : "";

  const isAppModeOnWeb = getWebStorage().getItem("isApp") === "Y";

  return {
    // isApp: true일 경우, native amplitude log를 사용하게 됨
    isApp,
    isV2App: isV2App(),
    isAppModeOnWeb,
    isIOS,
    isAndroid,
    osName,
    platform: getPlatform(osName),
    isWeb: !appVersion,
    appVersion,
    appVersionWithBuildNumber: appVersion
      ? `${appVersion}${buildNumber}`
      : null,
    isSupportedKakaoSDKV2: isSupportedKakaoSDKV2(appVersion),
    isSupportedAutoLineLogin: isSupportedAutoLineLogin(appVersion),
    isSupportedGlobalScreen: isSupportedGlobalScreen(appVersion),
    isSupportedNativePageEvent: isSupportedNativePageEvent(appVersion),
    isSupportedOnthelookStore: isSupportedOnthelookStore(appVersion),
    isSupportedNativeShare: isSupportedNativeShare(appVersion),
    isSupportedNativeUserPage: isSupportedNativeUserPage(appVersion),
    isSupportedFacebookLogin: isSupportedFacebookLogin(appVersion),
    isSupportedShorts: isSupportedShorts(appVersion),
    isSupportedUserInfo: isSupportedUserInfo(appVersion),
    isSupportedKakaoOpen: isSupportedKakaoOpen(appVersion),
    isProduction: isProduction(),
    isStage: isStage(),
    isDevelopment: isDevelopment(),
    isSearchEngineBot: isSearchEngineBot(),
    needForceUpdate: needForceUpdate(),
  };
}

export default useUserAgent;
