import React, { Children, isValidElement } from "react";
import styled from "styled-components";

import { maxWidth } from "core/styles/mixin";
import { useAppDownLoad } from "core/context/app-download-provider";

interface HeaderProps {
  children?: React.ReactNode;
}

const GNB = styled.header<{ isExposeAppDownload?: boolean }>`
  position: fixed;
  top: ${(props) =>
    props.isExposeAppDownload ? "var(--app-download-button-height)" : "0"};
  left: 0;
  height: var(--nav-bar-height);
  width: 100%;
  z-index: var(--nav-index);
  background: white;
  opacity: 0.95;
`;

const GridWrap = styled.div<{ fullSize?: boolean }>`
  margin: 0 auto;
  height: 100%;
  display: grid;
  grid-template-columns: ${(props) => (props.fullSize ? "1fr" : "1fr 1fr 1fr")};
  align-items: center;
  ${maxWidth}
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getElement = (children: React.ReactNode, nodeType: any) => {
  const childrenArray = Children.toArray(children);
  return childrenArray.find(
    (child) => isValidElement(child) && child.type === nodeType
  );
};

const StartAdorment = ({ children }: { children?: React.ReactNode }) => (
  <>{children}</>
);
const CenterAdorment = ({ children }: { children?: React.ReactNode }) => (
  <>{children}</>
);
const EndAdorment = ({ children }: { children?: React.ReactNode }) => (
  <>{children}</>
);
const StartAdormentType = (<StartAdorment />).type;
const CenterAdormentType = (<CenterAdorment />).type;
const EndAdormentType = (<EndAdorment />).type;

const Header = ({ children }: HeaderProps) => {
  const { isExposeAppDownload } = useAppDownLoad();
  const startAdorment = getElement(children, StartAdormentType);
  const centerAdorment = getElement(children, CenterAdormentType);
  const endAdorment = getElement(children, EndAdormentType);
  // 어떠한 adornment도 없을 경우 fullSize로 처리
  const isFullSize = !startAdorment && !endAdorment && !endAdorment;

  return (
    <GNB isExposeAppDownload={isExposeAppDownload}>
      <GridWrap fullSize={isFullSize}>
        {isFullSize ? (
          children
        ) : (
          <>
            <nav
              style={{
                gridColumn: 1,
                justifyContent: "flex-start",
                alignItems: "center",
                display: "flex",
              }}
            >
              {startAdorment}
            </nav>
            <nav
              style={{
                gridColumn: 2,
                alignItems: "center",
                textAlign: "center",
              }}
            >
              {centerAdorment}
            </nav>
            <nav
              style={{
                gridColumn: 3,
                alignItems: "center",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {endAdorment}
            </nav>
          </>
        )}
      </GridWrap>
    </GNB>
  );
};

export default Object.assign(Header, {
  StartAdorment,
  CenterAdorment,
  EndAdorment,
});
