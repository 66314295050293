import styled, { keyframes } from "styled-components";
import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

import RatingOnIcon from "../../images/icons/ic_rating_on.png";

import { useAmplitude } from "utils/amplitude/use-amplitude";
import useUserAgent from "core/user-agent/use-user-agent";
import { AirbridgeProvider } from "core/context/third-party";
import { maxWidth } from "core/styles/mixin";
import AppIconPng from "images/icons/appicon-onthelook@3x.png";
import { isInIframe } from "libs/iframe/utils/iframe-utils";

const highlight = keyframes`
  100% {
    background-position: right center;
  }
`;

const Container = styled.div`
  z-index: var(--nav-index);
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto;
  ${maxWidth}
`;

const Button = styled.button`
  width: 100%;
  height: var(--app-download-button-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;
  background: linear-gradient(
    90deg,
    #22a6ff 0.82%,
    #5873ff 17.35%,
    #ac6bff 33.87%,
    #baa1ff 50.4%,
    #ac6bff 66.92%,
    #5873ff 83.45%,
    #22a6ff 99.97%
  );
  background-size: 200% auto;
  animation: ${highlight} 2s infinite alternate;
`;

const AppIcon = styled.img`
  width: 38px;
  height: 38px;
`;

const TextWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const RoundButon = styled.div`
  border: 1.5px solid rgba(255, 255, 255, 0.8);
  border-radius: 20px;
  padding: 8px 12px;
  color: #fff;
  font-size: 12px;
  line-height: 1.2;
  font-weight: bold;
  white-space: nowrap;
`;

const MainText = styled.p`
  color: #fff;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  text-align: left;

  @media (min-width: 500px) {
    br {
      display: none;
    }
  }
`;

const IconTextWrap = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.3;
  margin-top: 2px;

  @media (min-width: 500px) {
    display: flex;
  }
`;

const StarIcon = styled.img`
  width: 12px;
  height: 12px;
  margin: 0 2px 2px 4px;
`;

const OpacitySpan = styled.span`
  opacity: 0.7;
`;

const AppDownloadButton = () => {
  const { track } = useAmplitude();
  const { platform } = useUserAgent();
  const averageRating = (4.8 || 4.8).toFixed(1);
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => {
      window.airbridge?.setDeeplinks({
        buttonID: "app_download",
        deeplinks: {
          ios: "otl://onthelook.co.kr",
          android: "otl://onthelook.co.kr",
          desktop: "https://abr.ge/xd6lti",
        },
        fallbacks: {
          ios: "itunes-appstore",
          android: "google-play",
        },
        defaultParams: {
          campaign: "bottom_BT",
          source: "organic",
        },
        desktopPopUp: true,
      });
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Container>
      <Button
        id="app_download"
        onClick={() => {
          track("click app download", {
            os: platform,
            where: window.location.pathname,
          });
        }}
      >
        <AppIcon src={AppIconPng} />
        <TextWrap>
          <MainText>
            <Trans i18nKey="webtoapp_top_msg" />
          </MainText>
          <IconTextWrap>
            <OpacitySpan
              style={{
                opacity: 0.7,
              }}
            >
              {t("webtoapp_top_review")}
            </OpacitySpan>
            <StarIcon src={RatingOnIcon} />
            <OpacitySpan
              style={{
                opacity: 0.7,
              }}
            >
              {averageRating}
            </OpacitySpan>
          </IconTextWrap>
        </TextWrap>
        <RoundButon>{t("webtoapp_top_btn")}</RoundButon>
      </Button>
    </Container>
  );
};

const AppDownloadButtonWrapper = () => {
  const { isSearchEngineBot } = useUserAgent();

  if (isSearchEngineBot || isInIframe()) {
    return null;
  }

  return (
    <AirbridgeProvider token={import.meta.env.VITE_AIRBRIDGE_TOKEN as string}>
      <AppDownloadButton />
    </AirbridgeProvider>
  );
};
export default AppDownloadButtonWrapper;
