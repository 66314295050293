import React from "react";
import reactStringReplace from "react-string-replace";

export const getReadableMessage = ({
  text,
  allowHashTag = false,
  allowHashTagBoldOnly = false,
  allowAlphaTag = false,
}) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let newMessage: any = reactStringReplace(text, "\\n", (match, i) => (
      <br key={match + i} />
    ));
    newMessage = reactStringReplace(
      newMessage,
      /(https?:\/\/\S+)/g,
      (match, i) => (
        <a
          key={match + i}
          href={match}
          target={"_blank"}
          style={{ color: "#2295ff" }}
          rel="noreferrer"
        >
          바로가기
        </a>
      )
    );
    if (allowHashTag) {
      newMessage = reactStringReplace(
        newMessage,
        /(#[a-zA-Z0-9가-힣\d-]+)/g,
        (match, i) => (
          <a
            key={match + i}
            href={`/search/result?q=${match.replace("#", "")}`}
            target={"_blank"}
            style={{ color: "#2295ff" }}
            rel="noreferrer"
          >
            {match}
          </a>
        )
      );
    }
    if (allowHashTagBoldOnly) {
      newMessage = reactStringReplace(
        newMessage,
        /(#[a-zA-Z0-9가-힣\d-]+)/g,
        (match, i) => <b key={match + i}>{match}</b>
      );
    }
    if (allowAlphaTag) {
      newMessage = reactStringReplace(
        newMessage,
        /(@[a-zA-Z0-9가-힣._-\d-]+)/g,
        (match, i) => (
          <a
            key={match + i}
            href={`/${match.replace("@", "")}`}
            target={"_blank"}
            style={{ color: "#2295ff" }}
            rel="noreferrer"
          >
            {match}
          </a>
        )
      );
    }

    return newMessage;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return text;
  }
};
