import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { maxWidth } from "core/styles/mixin";
import Button from "elements/button";
import { useStackNavigate } from "core/navigator";

const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  height: 100%;
  ${maxWidth}
  display: flex;
  align-items: flex-start;
  padding: var(--nav-bar-height) 16px var(--bottom-nav-bar-height);
`;

const NoInfo = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #999;
`;

const ButtonWrap = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: calc(16px + var(--bottom-nav-bar-height));
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;
`;

const Component = () => {
  const trans = useTranslation();
  const navigate = useStackNavigate();
  return (
    <Container>
      <NoInfo>{trans.t("wrong_page")}</NoInfo>
      <ButtonWrap>
        <Button
          onClick={() => {
            navigate("/", { type: "reset" });
          }}
        >
          {trans.t("go_to_home")}
        </Button>
      </ButtonWrap>
    </Container>
  );
};

export default Component;
