import { isAndroid } from "react-device-detect";

import { getAppVersion } from "./UserAgentUtil";

type SafeAreaPosition = "top" | "bottom";
type SafeAreaPrefix = "env" | "constant";

const getSafeAreaInsetByPos = ({
  position,
}: {
  position: SafeAreaPosition;
}): number => {
  // eslint-disable-next-line prefer-regex-literals
  const agentString = position === "top" ? "safe_area_t" : "safe_area_b";
  const regx = new RegExp(`${agentString}/(\\d+)`, "g");
  const match = window.navigator.userAgent.match(regx);

  if (match && match[0]) {
    // x.x.x 형태의 값을 return 합니다.
    return parseInt(match[0].split("/")[1]);
  }
  return 0;
};
export const getSafeAreaInsetTop = (prefix: SafeAreaPrefix) => {
  const agent = window.navigator.userAgent;
  const version = getAppVersion();
  const isV2App = version && Number(version.split(".")[0]) >= 2;

  if (isV2App) {
    return "0px";
  }

  if (isAndroid) {
    return "0px";
  }

  // Legacy App Support
  if (agent.indexOf("SafeAreaInsetsModeOn") !== -1) {
    return "0px";
  }

  // React Native Only
  if (agent.indexOf("safe_area_") !== -1) {
    return getSafeAreaInsetByPos({ position: "top" }) + "px";
  }

  // Safari Only
  return `${prefix}(safe-area-inset-top)`;
};

/**
 * @deprecated 2.0.0 앱에서는 더 이상 지원되지 않습니다.
 */
export const getSafeAreaInsetBottom = (prefix: SafeAreaPrefix) => {
  const agent = window.navigator.userAgent;
  const version = getAppVersion();
  const isV2App = version && Number(version.split(".")[0]) >= 2;

  if (isV2App) {
    return "0px";
  }

  if (isAndroid) {
    return "0px";
  }

  // Legacy App Support
  if (agent.indexOf("SafeAreaInsetsModeOn") !== -1) {
    return "34px";
  }

  // React Native Only
  if (agent.indexOf("safe_area_") !== -1) {
    return getSafeAreaInsetByPos({ position: "bottom" }) + "px";
  }

  // Safari Only
  return `${prefix}(safe-area-inset-bottom)`;
};

export const enableBodyScroll = () => {
  document.body.style.overflow = "";
};

export const disableBodyScroll = () => {
  document.body.style.overflow = "hidden";
};
