import { AmplitudeClient, Config } from "amplitude-js";
import React, { createContext, useMemo } from "react";
import * as Sentry from "@sentry/browser";

import useUserAgent from "core/user-agent/use-user-agent";

interface AmplitudeProviderProps {
  amplitudeInstance: AmplitudeClient;
  apiKey: string;
  config?: Config;
  callback?: (client: AmplitudeClient) => void;
  children: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isValidAmplitudeInstance(maybeInstance: any) {
  return (
    !!maybeInstance &&
    typeof maybeInstance.init === "function" &&
    typeof maybeInstance.logEvent === "function"
  );
}

const AmplitudeContext = createContext<Record<string, unknown> | undefined>(
  undefined
);

function initAmplitude(
  amplitudeInstance: AmplitudeClient,
  apiKey: string,
  config?: Config,
  // eslint-disable-next-line promise/prefer-await-to-callbacks
  callback?: (client: AmplitudeClient) => void
) {
  return () => {
    if (isValidAmplitudeInstance(amplitudeInstance)) {
      if (apiKey) {
        amplitudeInstance.init(apiKey, undefined, config, callback);
      }
    } else {
      Sentry.captureMessage("amplitude instance가 잘못되었습니다.");
    }
  };
}

const AmplitudeProvider = ({
  children,
  amplitudeInstance,
  apiKey,
  config,
  callback,
}: AmplitudeProviderProps) => {
  const { isSearchEngineBot } = useUserAgent();

  const init = useMemo(
    () => initAmplitude(amplitudeInstance, apiKey, config, callback),
    [amplitudeInstance, apiKey, config, callback]
  );

  // redirect Url의 경우, amplitude를 사용하지 않는다.
  if (!window.location.pathname.startsWith("/ru/") || !isSearchEngineBot) {
    init();
  }

  // TODO: useAmplitude 함수들 여기로 이동

  return (
    <AmplitudeContext.Provider value={{}}>{children}</AmplitudeContext.Provider>
  );
};

function useAmplitude() {
  const context = React.useContext(AmplitudeContext);
  if (context === undefined) {
    throw new Error("useAmplitude must be used within a AmplitudeProvider");
  }
  return context;
}

export { AmplitudeProvider, useAmplitude };
