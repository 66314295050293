import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { redirectLoginPage } from "Functions/LoginUtil";
import { toastBottom } from "Functions/OtlToast";
import { useAuth } from "core/context/auth-provider";
import {
  GetPostEngagementQuery,
  Post,
  PostItemPoint,
  useCancelLikePostMutation,
  useDoBookmarkMutation,
  useDoLikePostMutation,
  useCancelBookmarkMutation,
  useGetPostQuery,
  usePostItemPointsQuery,
  useDoSubscriptionMutation,
  useCancelSubscriptionMutation,
  useDoAlarmCeartorMutation,
  useCancelAlarmCeartorMutation,
  useDoDeletePostMutation,
} from "types/generated/graphql";
import { useAmplitude } from "utils/amplitude/use-amplitude";
import { GET_POST_ENGAGEMENT } from "Routes/post/post-detail/queries";
import useBroadcastRefetch from "core/channels/use-broadcast-refetch";

export const useGetPost = (postId: string, cache: boolean) => {
  const { data, loading, error } = useGetPostQuery({
    variables: { id: postId, cache },
    fetchPolicy: "cache-and-network",
  });

  const post = data?.post?.items as Post;

  return { post, loading, error };
};

export const useGetPostEngagement = (postId: string) => {
  const { data, loading, error } = useQuery<GetPostEngagementQuery>(
    GET_POST_ENGAGEMENT,
    {
      fetchPolicy: "cache-and-network",
      variables: { id: postId },
    }
  );

  const engagement = data?.result?.items;

  return {
    loading,
    error,
    engagement,
  };
};

export const useGetPostItemPoints = (postId: number) => {
  const { data, loading, error } = usePostItemPointsQuery({
    variables: { postId },
  });

  const itemPoints = data?.result.items as PostItemPoint[];

  return { itemPoints, loading, error };
};

export const useDoLike = ({
  postId,
  userId,
  targetUserId,
  targetUserInstagramId,
  targetUserGender,
  callbackFunction,
}: {
  postId: number;
  userId: number;
  targetUserId: number;
  targetUserInstagramId: string;
  targetUserGender: string;
  callbackFunction?: () => void;
}) => {
  const { track } = useAmplitude();
  const { user } = useAuth();

  const [doLikePost] = useDoLikePostMutation({
    variables: { postId },
    onCompleted: () => {
      track("like post", {
        Post_ID: postId,
        User_ID: userId,
        Creator_ID: targetUserId,
        "Creator Name": targetUserInstagramId || "",
        "Creator Gender": targetUserGender || "",
        Where: "post",
        source: "post detail",
      });

      if (callbackFunction) {
        callbackFunction();
      }
    },
  });

  return user ? doLikePost : redirectLoginPage;
};

export const useCancleLike = ({
  postId,
  callbackFunction,
}: {
  postId: number;
  callbackFunction?: () => void;
}) => {
  const { user } = useAuth();

  const [cancelLikePost] = useCancelLikePostMutation({
    variables: { postId },
    onCompleted: () => {
      if (callbackFunction) {
        callbackFunction();
      }
    },
  });

  return user ? cancelLikePost : redirectLoginPage;
};

export const useDoBookmarkPost = ({
  targetId,
  refererCreatorId,
  refererPostId,
  callbackFunction,
}: {
  targetId: number;
  refererCreatorId: number;
  refererPostId: number;
  callbackFunction?: () => void;
}) => {
  const { track } = useAmplitude();
  const { user } = useAuth();

  const [doBookmark] = useDoBookmarkMutation({
    variables: { type: "post", targetId, refererCreatorId, refererPostId },
    onCompleted: () => {
      track("do bookmark", {
        type: "post",
        targetId: targetId || 0,
        Target_ID: targetId || 0,
        refererCreatorId: refererCreatorId || 0,
        refererPostId: refererPostId || 0,
        Where: "post detail",
        source: "post detail",
      });

      if (callbackFunction) {
        callbackFunction();
      }
    },
  });

  return user ? doBookmark : redirectLoginPage;
};

export const useCancleBookmarkPost = ({
  targetId,
  callbackFunction,
}: {
  targetId: number;
  callbackFunction?: () => void;
}) => {
  const [cancleBookmark] = useCancelBookmarkMutation({
    variables: { type: "post", targetId },
    onCompleted: () => {
      if (callbackFunction) {
        callbackFunction();
      }
    },
  });

  return cancleBookmark;
};

export const useDoSubscription = ({
  targetUserId,
  callbackFunction,
}: {
  targetUserId: number;
  callbackFunction?: () => void;
}) => {
  const { user } = useAuth();
  const { refetchSchema } = useBroadcastRefetch();

  const [doSubscription] = useDoSubscriptionMutation({
    variables: { targetUserId },
    onCompleted: () => {
      if (callbackFunction) {
        callbackFunction();
      }
      refetchSchema("subscribed-creator");
    },
  });

  return user ? doSubscription : redirectLoginPage;
};

export const useCancelSubscription = ({
  targetUserId,
  callbackFunction,
}: {
  targetUserId: number;
  callbackFunction?: () => void;
}) => {
  const { user } = useAuth();
  const { refetchSchema } = useBroadcastRefetch();

  const [cancelSubscription] = useCancelSubscriptionMutation({
    variables: { targetUserId },
    onCompleted: () => {
      if (callbackFunction) {
        callbackFunction();
      }
      refetchSchema("subscribed-creator");
    },
  });

  return user ? cancelSubscription : redirectLoginPage;
};

export const useDoAlarmCreator = ({
  targetUserId,
  callbackFunction,
}: {
  targetUserId: number;
  callbackFunction?: () => void;
}) => {
  const { user } = useAuth();
  const trans = useTranslation();

  const [doAlarmCreator] = useDoAlarmCeartorMutation({
    variables: { targetUserId },
    onCompleted: (res) => {
      if (res?.result?.ok) {
        toastBottom(trans.t("alarm_on_message"));
      }
      if (callbackFunction) {
        callbackFunction();
      }
    },
  });

  return user ? doAlarmCreator : redirectLoginPage;
};

export const useCancelAlarmCreator = ({
  targetUserId,
  callbackFunction,
}: {
  targetUserId: number;
  callbackFunction?: () => void;
}) => {
  const { user } = useAuth();
  const trans = useTranslation();

  const [cancelAlarmCreator] = useCancelAlarmCeartorMutation({
    variables: { targetUserId },
    onCompleted: (res) => {
      if (res?.result?.ok) {
        toastBottom(trans.t("alarm_off_message"));
      }
      if (callbackFunction) {
        callbackFunction();
      }
    },
  });

  return user ? cancelAlarmCreator : redirectLoginPage;
};

export const useDoBookmarkProduct = ({
  targetId,
  refererCreatorId,
  refererPostId,
  callbackFunction,
}: {
  targetId: number;
  refererCreatorId: number;
  refererPostId: number;
  callbackFunction?: () => void;
}) => {
  const { user } = useAuth();
  const { track } = useAmplitude();

  const [doBookmarkProduct] = useDoBookmarkMutation({
    variables: { type: "product", targetId, refererCreatorId, refererPostId },
    onCompleted: () => {
      track("do bookmark", {
        type: "product",
        targetId: targetId || 0,
        Target_ID: targetId || 0,
        refererCreatorId: refererCreatorId || 0,
        refererPostId: refererPostId || 0,
        Where: "post detail",
        source: "post detail",
      });
      if (callbackFunction) {
        callbackFunction();
      }
    },
  });

  return user ? doBookmarkProduct : redirectLoginPage;
};

export const useCancelBookmarkProduct = ({
  targetId,
  callbackFunction,
}: {
  targetId: number;
  refererPostId: number;
  callbackFunction?: () => void;
}) => {
  const { user } = useAuth();
  const [cancelBookmarkProduct] = useCancelBookmarkMutation({
    variables: { type: "product", targetId },
    onCompleted: () => {
      if (callbackFunction) {
        callbackFunction();
      }
    },
  });

  return user ? cancelBookmarkProduct : redirectLoginPage;
};

export const useDoDeletePost = ({
  postId,
  callbackFunction,
}: {
  postId: number;
  callbackFunction?: () => void;
}) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [doDeletePost] = useDoDeletePostMutation({
    variables: { id: postId },
    onCompleted: (res) => {
      if (res.DeletePost.ok) {
        navigate("/mypage");
      } else {
        toastBottom(
          "포스트 삭제에 문제가 발생했습니다. 관리자에게 문의 부탁드릴게요."
        );
      }

      if (callbackFunction) {
        callbackFunction();
      }
    },
  });

  return user ? doDeletePost : redirectLoginPage;
};
