import React from "react";
import styled from "styled-components";

import { getSafeAreaInsetBottom } from "Functions/LayoutUtil";
import { maxWidth } from "core/styles/mixin";

export interface ButtonProps {
  onClick?: () => void;
  background?: string;
  color?: string;
  fontSize?: string;
  lineHeight?: string;
  fontWeight?: string;
  children?: React.ReactNode;
  floating?: boolean;
}

const FloatingButtonWrap = styled.div<{ floating?: boolean }>`
  width: 100%;
  height: fit-content;
  ${({ floating }) =>
    floating &&
    `{
    position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: calc(34px + ${getSafeAreaInsetBottom("env")});
  z-index: 2;
  ${maxWidth}
  }`};
`;

const ButtonWrap = styled.div<{ background?: string }>`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-radius: 8px;
  background: ${({ background }) => background || "#111"};
`;

const TextWrap = styled.div<{
  color?: string;
  fontSize?: string;
  lineHeight?: string;
  fontWeight?: string;
}>`
  color: ${({ color }) => color || "#FFF"};
  font-weight: ${({ fontWeight }) => fontWeight || "400"};
  font-size: ${({ fontSize }) => fontSize || "15px"};
  line-height: ${({ lineHeight }) => lineHeight || "18px"};
`;

const Button = ({
  onClick,
  children,
  background,
  color,
  fontSize,
  lineHeight,
  fontWeight,
  floating,
}: ButtonProps) => {
  return (
    <FloatingButtonWrap floating={floating}>
      <ButtonWrap onClick={onClick} background={background}>
        <TextWrap
          color={color}
          fontSize={fontSize}
          lineHeight={lineHeight}
          fontWeight={fontWeight}
        >
          {children}
        </TextWrap>
      </ButtonWrap>
    </FloatingButtonWrap>
  );
};

export default Button;
