import React from "react";
import styled from "styled-components";

import { useNativeEvent } from "core/channels/use-native-event";
import { getReadableMessage } from "Functions/TextUtil";
import { maxWidth } from "core/styles/mixin";
import ModalPortal from "elements/modal/modal-portal";

const ModalOverLay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  z-index: 100;
  ${maxWidth}
  margin: auto;
`;

const PopupBodyWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  width: 300px;
  max-width: 600px;
  border-radius: 6px;
`;

const InfoWrap = styled.div`
  padding: 32px 16px;
  width: 100%;
  text-align: center;
`;

const ImageInfoWrap = styled.div`
  width: 100%;
`;

const PopupTitle = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: #111;
  margin-bottom: 12px;
`;

const PopupDesc = styled.div`
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  text-align: center;
  color: #666;
  -webkit-font-smoothing: antialiased;
`;

const PopupImg = styled.img`
  width: 100%;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Button = styled.div`
  width: 100%;
  border-top: 1px solid #eee;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  padding: 14px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #111;
  cursor: pointer;
`;

interface Props {
  isOpen: boolean;
  title: string;
  message: string;
  imageUrl?: string;
  disableBackDrop?: boolean;
  buttonText: string;
  onClickBackDrop?: () => void;
  onClickButton: () => void;
}

const Alert = ({
  isOpen,
  title,
  message,
  imageUrl,
  buttonText,
  disableBackDrop,
  onClickBackDrop,
  onClickButton,
}: Props) => {
  const { vibrate } = useNativeEvent();
  if (!isOpen) return null;

  return (
    <ModalPortal>
      <ModalOverLay
        onClick={() => {
          if (disableBackDrop) {
            vibrate();
          } else if (onClickBackDrop) {
            onClickBackDrop();
          } else {
            onClickButton();
          }
        }}
      >
        <PopupBodyWrap>
          <InfoWrap>
            <PopupTitle>{getReadableMessage({ text: title })}</PopupTitle>
            <PopupDesc>{getReadableMessage({ text: message })}</PopupDesc>
          </InfoWrap>
          {imageUrl && (
            <ImageInfoWrap>
              <PopupImg src={imageUrl} />
            </ImageInfoWrap>
          )}
          <ButtonWrap>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                vibrate();
                onClickButton();
              }}
            >
              {buttonText}
            </Button>
          </ButtonWrap>
        </PopupBodyWrap>
      </ModalOverLay>
    </ModalPortal>
  );
};

export default Alert;
