import React from "react";
import styled from "styled-components";

import { getSafeAreaInsetBottom } from "Functions/LayoutUtil";
import { useAppDownLoad } from "core/context/app-download-provider";

interface MainProps {
  children?: React.ReactNode;
  headerless?: boolean;
  bottomless?: boolean;
  additionalMarginTop?: number;
}

const Wrapper = styled.main<{
  bottomless?: boolean;
  margin: string;
}>`
  margin-top: ${(props) => props.margin};
  flex: 1;
  margin-bottom: calc(
    ${(props) => (props.bottomless ? "0" : "var(--bottom-nav-bar-height)")} +
      ${getSafeAreaInsetBottom("env")}
  );
`;

const Main = ({ children, ...props }: MainProps) => {
  const { isExposeAppDownload } = useAppDownLoad();

  const navHeight = props.headerless ? "0px" : "var(--nav-bar-height)";
  const appDownLoadButtonHeight = isExposeAppDownload
    ? "var(--app-download-button-height)"
    : "0px";
  const mainMarginTop = `calc(${navHeight} + ${appDownLoadButtonHeight} + ${
    props.additionalMarginTop ?? "0"
  }px)`;

  return (
    <Wrapper {...props} margin={mainMarginTop}>
      {children}
    </Wrapper>
  );
};

export default Main;
