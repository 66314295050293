import React, { createContext } from "react";

interface FacebookPixelProviderProps {
  children: React.ReactNode;
  pixelId: string;
}

const FacebookPixelContext = createContext<Record<string, unknown> | undefined>(
  undefined
);

function getFbPixelInitScript(pixelId: string) {
  return `
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '${pixelId}');
fbq('track', 'PageView');
fbq.disablePushState = true;
`;
}

const initFacebookPixel = (innerHTML: string) => {
  const head = document.querySelector("head");
  const script = document.createElement("script");
  script.defer = true;
  if (innerHTML) script.innerHTML = innerHTML;
  head?.appendChild(script);
};

const FacebookPixelProvider = ({
  children,
  pixelId,
}: FacebookPixelProviderProps) => {
  initFacebookPixel(getFbPixelInitScript(pixelId));
  return (
    <FacebookPixelContext.Provider value={undefined}>
      {children}
    </FacebookPixelContext.Provider>
  );
};

export default FacebookPixelProvider;
