import React, { createContext } from "react";

import useUserAgent from "core/user-agent/use-user-agent";

interface KakaoSDKProviderProps {
  children: React.ReactNode;
}

const KakaoSDKContext = createContext<Record<string, unknown> | undefined>(
  undefined
);

const initKakaoSDK = (url: string) => {
  let scriptNode: HTMLScriptElement | null = document.querySelector(
    `script[src="${url}"]`
  );

  if (!scriptNode) {
    scriptNode = document.createElement("script");
    scriptNode.src = url;
    scriptNode.defer = true;
    scriptNode.integrity =
      "sha384-x+WG2i7pOR+oWb6O5GV5f1KN2Ko6N7PTGPS7UlasYWNxZMKQA63Cj/B2lbUmUfuC";
    scriptNode.crossOrigin = "anonymous";
    scriptNode.onload = () => {
      if (window.Kakao && !window.Kakao.isInitialized()) {
        window.Kakao.init(import.meta.env.VITE_KAKAO_KEY);
      }
    };
    const head = document.querySelector("head");
    head?.appendChild(scriptNode);
  }
};

const KakaoSDKProvider = ({ children }: KakaoSDKProviderProps) => {
  const { isSearchEngineBot } = useUserAgent();

  if (!isSearchEngineBot) {
    initKakaoSDK("https://t1.kakaocdn.net/kakao_js_sdk/2.2.0/kakao.min.js");
  }

  return (
    <KakaoSDKContext.Provider value={undefined}>
      {children}
    </KakaoSDKContext.Provider>
  );
};

export default KakaoSDKProvider;
