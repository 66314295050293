import React from "react";
import styled from "styled-components";

import NoticeIcon from "images/icons/alarm.svg";

const Icon = styled.img`
  width: 32px;
  height: 32px;
`;

const Count = styled.div<{ right?: number }>`
  height: 16px;
  min-width: 16px;
  padding-left: 4px;
  padding-right: 4px;
  background-color: #ff2c58;
  color: white;
  font-size: 11px;
  position: absolute;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  ${({ right }) => `right: ${right}px;`}
  z-index: 1;
`;

const Wrap = styled.div`
  position: relative;
`;

const getMargin = (count: number) => {
  if (count <= 9) {
    return 0;
  } else if (count <= 99) {
    return -6;
  } else {
    return -10;
  }
};

const Notification = ({ count }: { count?: number }) => {
  return (
    <Wrap>
      <Icon src={NoticeIcon} alt="notice" />
      {/* count가 0일 때를 대비하여 추가 */}
      {!!count && (
        <Count right={getMargin(count)}>{count <= 99 ? count : "99+"}</Count>
      )}
    </Wrap>
  );
};

export default Notification;
