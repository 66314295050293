import React, { createContext, useEffect } from "react";

import useUserAgent from "core/user-agent/use-user-agent";

interface GoogleAnalyticsProviderProps {
  children: React.ReactNode;
  gtmId: string;
  gaId: string;
}

const GoogleAnalyticsContext = createContext<undefined>(undefined);

function getGAScript(gtmId: string, gaId: string) {
  return `
(function(w, d, s, l) {
w[l] = w[l] || [];
function gtag(){w[l].push(arguments);}
gtag('js', new Date());
gtag('config', 'G-WSQLQYRNF4');
w[l].push({ event: 'gtm.js', 'gtm.start': new Date().getTime() })
var f = d.getElementsByTagName(s)[0],
gtm = d.createElement(s),
ga4 = d.createElement(s);
gtm.async = true;
ga4.async = true;
gtm.src = "https://www.googletagmanager.com/gtm.js?id=${gtmId}";
ga4.src = "https://www.googletagmanager.com/gtag/js?id=${gaId}"
f.parentNode.insertBefore(gtm, f)
f.parentNode.insertBefore(ga4, f)
})(window, document, 'script', 'dataLayer');
`;
}

const initGoogleAnalytics = (innerHTML: string) => {
  const head = document.querySelector("head");
  const script = document.createElement("script");
  script.defer = true;
  if (innerHTML) script.innerHTML = innerHTML;
  head?.appendChild(script);
};

/**
 * GTM을 사용하기 위한 Provider입니다.
 * 최초 화면 구성 시 호출이 필요한 경우가 아니라면 lazyload 합니다.
 * 검색엔진이 접근 시에는 호출하지 않습니다.
 */
const GoogleAnalyticsProvider = ({
  children,
  gtmId,
  gaId,
}: GoogleAnalyticsProviderProps) => {
  const { isSearchEngineBot } = useUserAgent();

  useEffect(() => {
    const init = () => {
      if (!isSearchEngineBot) {
        initGoogleAnalytics(getGAScript(gtmId, gaId));
      }
      window.removeEventListener("scroll", init);
    };

    // 사용자 스크롤이 발생하기 전에 GTM을 호출하지 않습니다.
    window.addEventListener("scroll", init);
  }, [gaId, gtmId, isSearchEngineBot]);

  return (
    <GoogleAnalyticsContext.Provider value={undefined}>
      {children}
    </GoogleAnalyticsContext.Provider>
  );
};

export default GoogleAnalyticsProvider;
