import { useEffect, useState, useCallback } from "react";
import { BroadcastChannel } from "broadcast-channel";

type refetchableSchemas = "bookmark" | "subscribed-creator" | "post" | "auth";

interface UseBroadcast {
  schemasToRefetch: refetchableSchemas[];
  refetchSchema: (schema: refetchableSchemas) => void;
}

function useBroadcastRefetch(): UseBroadcast {
  const [schemasToRefetch, setSchemasToRefetch] = useState<
    refetchableSchemas[]
  >([]);
  const [broadcastChannel, setBroadcastChannel] =
    useState<BroadcastChannel | null>(null);

  useEffect(() => {
    const channel: BroadcastChannel<refetchableSchemas[]> =
      new BroadcastChannel("query-refetch");
    setBroadcastChannel(channel);

    channel.onmessage = (event) => {
      setSchemasToRefetch(event);
    };

    return () => {
      channel.close();
    };
  }, []);

  const refetchSchema = useCallback(
    (schema: refetchableSchemas) => {
      if (broadcastChannel) {
        broadcastChannel.postMessage([...schemasToRefetch, schema]);
      }
    },
    [broadcastChannel, schemasToRefetch]
  );

  return { schemasToRefetch, refetchSchema };
}

export default useBroadcastRefetch;
