import React from "react";
import { IconButton } from "@onthelook/ui";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";

import { useNativeEvent } from "core/channels/use-native-event";
import useUserAgent from "core/user-agent/use-user-agent";
import { toastBottom } from "Functions/OtlToast";

const createCurrentUrl = () => {
  const url = window.location.href;
  const utmString = "utm_source=onthelook&utm_medium=share_link";
  if (!url.includes("utm")) {
    return url.includes("?") ? `${url}&${utmString}` : `${url}?${utmString}`;
  }
  return url;
};

const Share = ({ onClick }: { onClick: () => void }) => {
  const trans = useTranslation();
  const { vibrate, shareLink } = useNativeEvent();
  const { isSupportedNativeShare } = useUserAgent();
  const currentUrl = createCurrentUrl();
  const handleClick = () => {
    vibrate();
    onClick();
  };

  return (
    <CopyToClipboard
      text={currentUrl}
      onCopy={() => {
        if (isSupportedNativeShare) shareLink(currentUrl);
        else toastBottom(trans.t("uri_copied"));
      }}
    >
      <IconButton
        name="sharePrimaryLineEnabled"
        aria-label="share"
        iconSize={28}
        onClick={handleClick}
      />
    </CopyToClipboard>
  );
};

export default Share;
