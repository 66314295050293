/* eslint-disable no-console */
const DEBUG = import.meta.env.VITE_API_PROFILE === "development";

export const printLog = ({ text = "", args = null }) => {
  if (DEBUG) {
    console.log(text, args);
  }
};

export const printError = ({ text = "", args = null }) => {
  if (DEBUG) {
    console.error(text, args);
  }
};

export const printWarn = ({ text = "", args = null }) => {
  if (DEBUG) {
    console.warn(text, args);
  }
};

export const printInfo = ({ text = "", args = null }) => {
  if (DEBUG) {
    console.info(text, args);
  }
};
