import * as Sentry from "@sentry/browser";

import { getOS, isApp } from "./UserAgentUtil";

/**
 * @deprecated 이 메소드는 더 이상 지원되지 않습니다. useNativeEvent hook을 사용하세요.
 */
export const isSupportIOSJsBridgeV1 = () => {
  const w = window as Window;
  return w.webkit && w.webkit.messageHandlers && w.webkit.messageHandlers.OTL;
};

/**
 * @deprecated 이 메소드는 더 이상 지원되지 않습니다. useNativeEvent hook을 사용하세요.
 */
export const isSupportIOSJsBridgeV2 = () => {
  const w = window as Window;
  return (
    w.webkit && w.webkit.messageHandlers && w.webkit.messageHandlers.OTL_V2
  );
};

/**
 * @deprecated 이 메소드는 더 이상 지원되지 않습니다. useNativeEvent hook을 사용하세요.
 */
export const requestReviewFlow = () => {
  if (!isApp()) {
    return;
  }

  const os = getOS();
  const w = window as Window;

  try {
    if (os === "iOS") {
      if (isSupportIOSJsBridgeV2()) {
        const msg = {
          method: "requestReviewFlow",
          data: {},
        };
        w.webkit.messageHandlers.OTL_V2.postMessage(JSON.stringify(msg));
      }
    } else if (os === "Android") {
      if (w.OTL && w.OTL.requestReviewFlow) {
        w.OTL.requestReviewFlow();
      }
    }
  } catch (error) {
    Sentry.captureException(error);
  }
};

/**
 * @deprecated 이 메소드는 더 이상 지원되지 않습니다. useNativeEvent hook을 사용하세요.
 */
export const isSupportVibrate = () => {
  // set default
  if (!localStorage.getItem("isSupportVib")) {
    localStorage.setItem("isSupportVib", "Y");
  }
  return localStorage.getItem("isSupportVib") === "Y";
};

/**
 * @deprecated 이 메소드는 더 이상 지원되지 않습니다. useNativeEvent hook을 사용하세요.
 */
export const setSupportVib = (onoff: string) => {
  localStorage.setItem("isSupportVib", onoff);
};
/**
 * @deprecated 이 메소드는 더 이상 지원되지 않습니다. useNativeEvent hook을 사용하세요.
 */
export const vibrate = () => {
  if (!isSupportVibrate()) {
    return;
  }
  const defaultDuration = 10;
  const w = window as Window;
  const os = getOS();

  try {
    if (os === "iOS") {
      if (isSupportIOSJsBridgeV2()) {
        const msg = {
          method: "vibrate",
          data: {},
        };
        w.webkit.messageHandlers.OTL_V2.postMessage(JSON.stringify(msg));
      }
    } else if (os === "Android") {
      if (w.OTL && w.OTL.vibrate) {
        w.OTL.vibrate(defaultDuration);
      }
    }
  } catch (error) {
    Sentry.captureException(error);
  }
};
