import { PROMOTION_KEYWORDS } from "Routes/Promotion/banner";
import { getWebStorage } from "core/storage";
import { SearchFilter } from "types/generated/graphql";

export const DEFAULT_FILTER: SearchFilter = {
  bodyType: [],
  color: [],
  gender: [],
  height: [],
  item: [],
  mood: [],
  price: [1000, 200000],
  randomMood: "false",
  season: [],
  selectedCategory: "",
  selectedSubCategory: "",
  tpo: [],
  weight: [],
};

const getDefaultFilterWithGender = () => {
  const storage = getWebStorage();
  const gender = storage.getItem("gender");

  const filter = DEFAULT_FILTER;
  filter.gender = gender ? [gender] : [];

  return filter;
};

export const getResultUrl = ({
  prevQuery,
  newQuery,
}: {
  prevQuery: string | "";
  newQuery: string;
}): string => {
  const currentFullUrl = window.location.href;
  const domain = window.location.protocol + "//" + window.location.host;
  const removeDomainUrl = currentFullUrl.replace(domain, "");
  const replacedResultUrl = removeDomainUrl.replace("intro", "result");

  if (prevQuery) {
    const resultUrl = replacedResultUrl.replace(
      encodeURIComponent(prevQuery),
      encodeURIComponent(newQuery)
    );

    return resultUrl;
  } else {
    if (replacedResultUrl.includes("q=&")) {
      const resultUrl = replacedResultUrl.replace(
        "q=&",
        `q=${encodeURIComponent(newQuery)}&`
      );

      // NOTE: 프로모션 끝나면 제거 예정
      if (PROMOTION_KEYWORDS.includes(newQuery)) {
        if (resultUrl.includes("t=post")) {
          return resultUrl.replace("t=post", "t=product");
        }

        if (!resultUrl.includes("t=product")) {
          return resultUrl + "t=product";
        }
      }

      return resultUrl;
    } else {
      // 인트로 페이지로 바로 접근한 경우 q 파라미터를 추가하여 result Url을 생성합니다.
      const resultUrl =
        replacedResultUrl + `?q=${encodeURIComponent(newQuery)}`;

      // NOTE: 프로모션 끝나면 제거 예정
      if (
        PROMOTION_KEYWORDS.includes(newQuery) &&
        !resultUrl.includes("t=product")
      ) {
        return resultUrl + "&t=product";
      }

      return resultUrl;
    }
  }
};

export const getIntroUrl = ({ resetQuery }: { resetQuery?: boolean }) => {
  const currentUrl = window.location.href;
  const domain = window.location.protocol + "//" + window.location.host;
  const removeDomainUrl = currentUrl.replace(domain, "");
  const introDefaultUrl = removeDomainUrl.replace("result", "intro");

  const introUrl = resetQuery
    ? introDefaultUrl.replace(/q=.+&t/, "q=&t")
    : introDefaultUrl;

  return introUrl;
};

export const getSearchPageUrl = ({ type }: { type: "intro" | "result" }) => {
  const defaultFilter = getDefaultFilterWithGender();
  const searchPageUrl = `/search/${type}?q=&t=post&f=${JSON.stringify(
    defaultFilter
  )}&vt=2&st=POPULAR_ITEM`;

  return searchPageUrl;
};
