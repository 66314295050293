import React from "react";
import { Link } from "react-router-dom";

import {
  NavigationAction,
  ScreenType,
  sendMessageToNative,
} from "core/channels/bridge-handler";
import useUserAgent from "core/user-agent/use-user-agent";
import { useAppDownLoad } from "core/context/app-download-provider";
import { useAuth } from "core/context/auth-provider";

interface StackLinkProps {
  children: React.ReactNode;
  to: string;
  screenType?: ScreenType;
  params?: { [index: string]: unknown };
  type?: NavigationAction;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  active?: boolean;
}

const StackLink = ({
  children,
  to,
  type = "push",
  screenType = "webview",
  params: StackParams,
  className,
  onClick,
  active = true,
}: StackLinkProps) => {
  const { appVersion, isApp } = useUserAgent();
  const isStackSupportApp = appVersion && Number(appVersion.split(".")[0]) >= 2;
  const { isExposeAppDownload, openSheet } = useAppDownLoad();
  const { user } = useAuth();

  if (!active) {
    return <div className={className}>{children}</div>;
  }

  if (isApp && isStackSupportApp) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <a
        className={className}
        onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
          e.stopPropagation();
          e.preventDefault();
          if (active) {
            onClick?.(e);
            sendMessageToNative("NATIVE_EVENT", {
              method: "navigate",
              params: {
                navigationAction: type,
                path: to,
                screenType,
                params: StackParams,
              },
            });
          }
        }}
        style={{ cursor: "pointer" }}
      >
        {children}
      </a>
    );
  }

  // TODO: 앱 알람 이동에 대한 임시 처리
  if (isExposeAppDownload && to === "/notification" && !user) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <a className={className} onClick={openSheet}>
        {children}
      </a>
    );
  }

  return (
    <Link to={to} onClick={onClick} className={className}>
      {children}
    </Link>
  );
};

export default StackLink;
