import React, { useMemo } from "react";
import styled from "styled-components";

import { useStackNavigate } from "core/navigator";
import { useAmplitude } from "utils/amplitude/use-amplitude";
import { useSearchShortsQuery } from "types/generated/graphql";
import { isKorean } from "Functions/LanguageUtil";

export const PROMOTION_MAP: {
  [key: string]: {
    value: string;
    tag: string;
    sortImage: string;
    sortWidth: number;
    bannerImage: string;
    bannerHeight: number;
    bannerBg: string;
    bannerFallbackLink: string;
  };
} = {
  콜드웜특가: {
    value: "콜드웜특가",
    tag: "콜드웜",
    sortImage: "",
    sortWidth: 60,
    bannerImage: "",
    bannerHeight: 60,
    bannerBg: "",
    bannerFallbackLink: "",
  },
  홍또기: {
    value: "홍또기",
    tag: "콜드웜",
    sortImage: "",
    sortWidth: 60,
    bannerImage: "",
    bannerHeight: 60,
    bannerBg: "",
    bannerFallbackLink: "",
  },
  델룸픽: {
    value: "델룸픽",
    tag: "콜드웜",
    sortImage: "",
    sortWidth: 60,
    bannerImage: "",
    bannerHeight: 60,
    bannerBg: "",
    bannerFallbackLink: "",
  },
  지노픽: {
    value: "지노픽",
    tag: "콜드웜",
    sortImage: "",
    sortWidth: 60,
    bannerImage: "",
    bannerHeight: 60,
    bannerBg: "",
    bannerFallbackLink: "",
  },
  패딩베스트할인: {
    value: "패딩베스트할인",
    tag: "콜드웜",
    sortImage: "",
    sortWidth: 60,
    bannerImage: "",
    bannerHeight: 60,
    bannerBg: "",
    bannerFallbackLink: "",
  },
  팬츠기획전: {
    value: "팬츠기획전",
    tag: "콜드웜",
    sortImage: "",
    sortWidth: 60,
    bannerImage: "",
    bannerHeight: 60,
    bannerBg: "",
    bannerFallbackLink: "",
  },
};

const INTRO_BANNER: {
  [key: string]: {
    value: string;
    bannerImage: string;
    bannerHeight: number;
    bannerBg: string;
  };
} = {};

export const PROMOTION_KEYWORDS = Object.keys(PROMOTION_MAP);
export const INTRO_BANNER_KEYWORDS = Object.keys(INTRO_BANNER);

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Banner = ({ where }: { where: "home" | "search-intro" }) => {
  const stackNavigate = useStackNavigate();
  const { track } = useAmplitude();

  const mainBanner = useMemo(() => {
    const randomIdx = Math.floor(Math.random() * INTRO_BANNER_KEYWORDS.length);
    return INTRO_BANNER[INTRO_BANNER_KEYWORDS[randomIdx]];
  }, []);

  const { data, loading, error } = useSearchShortsQuery({
    variables: {
      query: mainBanner?.value || "",
      page: 0,
      perPage: 1,
    },
  });

  const showHomeBanner =
    !loading && !!data?.SearchShorts?.items?.length && !!mainBanner;

  if (!isKorean || !showHomeBanner || !!error) return null;

  return (
    <Container
      style={{
        background: mainBanner.bannerBg,
      }}
      onClick={() => {
        track("click promotion banner", {
          Where: where,
          Keyword: mainBanner.value,
        });
        stackNavigate(
          `/search/result?q=${mainBanner.value}&t=product&from=result-intro`
        );
      }}
    >
      <img
        src={mainBanner.bannerImage}
        style={{ height: mainBanner.bannerHeight }}
        alt="search intro promotion banner"
      />
    </Container>
  );
};

export default Banner;
