import React from "react";

import StackLink from "./stack-link";

import { NavigationAction } from "core/channels/bridge-handler";

const StackLinkWrapper = ({
  href,
  children,
  type,
}: {
  href?: string | null;
  type?: NavigationAction;
  children: React.ReactNode;
}) =>
  href ? (
    <StackLink to={href} type={type}>
      {children}
    </StackLink>
  ) : (
    <>{children}</>
  );

export default StackLinkWrapper;
