import React, { useMemo } from "react";
import { Outlet } from "react-router-dom";

import Layout from "elements/layout";
import NavBar from "elements/navbar";
import Notification from "elements/icon/notification";
import { useNavHeaderQueryQuery } from "types/generated/graphql";
import { useStackNavigate } from "core/navigator";
import useUserAgent from "core/user-agent/use-user-agent";
import { PostDetailProvider } from "Components/post-detail/post-detail-provider";

const PostLayout = () => {
  const navigate = useStackNavigate();
  const { data } = useNavHeaderQueryQuery();

  const { appVersion } = useUserAgent();

  const needHomeButton = useMemo(() => appVersion === "2.0.0", [appVersion]);

  return (
    <Layout>
      <Layout.Header>
        <Layout.Header.StartAdorment>
          <NavBar marginLeft="20px">
            <NavBar.Item onClick={() => navigate(-1)} icon="back" />
          </NavBar>
          {needHomeButton && (
            <NavBar.Item
              icon="home"
              onClick={() => navigate("/", { type: "popToTop" })}
            />
          )}
        </Layout.Header.StartAdorment>
        <Layout.Header.EndAdorment>
          <NavBar marginRight="18px">
            <NavBar.Item href="/notification">
              <Notification count={data?.GetNotiCount?.count || 0} />
            </NavBar.Item>
          </NavBar>
        </Layout.Header.EndAdorment>
      </Layout.Header>
      <Layout.Main>
        <Outlet />
      </Layout.Main>
      <Layout.BottomNav />
    </Layout>
  );
};

const ProviderWithLayout = () => {
  return (
    <PostDetailProvider>
      <PostLayout />
    </PostDetailProvider>
  );
};

export default ProviderWithLayout;
