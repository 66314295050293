import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getSafeAreaInsetBottom } from "Functions/LayoutUtil";
import { useAuth } from "core/context/auth-provider";
import { useNativeEvent } from "core/channels/use-native-event";
import { useAmplitude } from "utils/amplitude/use-amplitude";
import useUserAgent from "core/user-agent/use-user-agent";
import { getSearchPageUrl } from "Routes/post/search-intro/utils";
import { maxWidth } from "core/styles/mixin";
import { useAppDownLoad } from "core/context/app-download-provider";

const baseUrl = "https://images.onthelook.co.kr/app/navs/";
const SearchNorIcon = baseUrl + "ic_tabbar_search_nor.svg";
const SearchSelIcon = baseUrl + "ic_tabbar_search_sel.svg";
const HomeNorIcon = baseUrl + "ic_tabbar_home_nor.svg";
const HomeSelIcon = baseUrl + "ic_tabbar_home_sel.svg";
const BookmarkNorIcon = baseUrl + "ic_tabbar_bookmark_nor.svg";
const BookmarkSelIcon = baseUrl + "ic_tabbar_bookmark_sel.svg";
const MyNorIcon = baseUrl + "ic_tabbar_my_nor.svg";
const MySelIcon = baseUrl + "ic_tabbar_my_sel.svg";

interface NavType {
  key: string;
  to: string;
  activePath: string[];
  name: string;
  activeIcon: string;
  defaultIcon: string;
  width: number;
  height?: number;
}

const ICON_DEFAULT_WIDTH = 20;
const ICON_DEFAULT_HEIGHT = 20;

const Footer = styled.footer`
  z-index: var(--nav-index);
  position: fixed;
  height: calc(var(--bottom-nav-bar-height) + ${getSafeAreaInsetBottom("env")});
  left: 0;
  bottom: 0;
  width: 100%;
`;

const Nav = styled.nav<{ count: number }>`
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  margin: 0 auto;
  ${maxWidth}
  height: 100%;
  display: grid;
  grid-template-columns: repeat(${({ count }) => count}, minmax(0, 1fr));
  padding: 0 8px ${getSafeAreaInsetBottom("env")} 8px;
  background-color: white;
`;

const TabIcon = styled.img<{
  width: number;
  height?: number;
}>`
  width: ${(props) =>
    props.width ? `${props.width}px` : `${ICON_DEFAULT_WIDTH}px;`};
  height: ${(props) => (props.height ? `${props.height}px` : "auto;")};
`;

const NavItem = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media (max-width: 320px) {
    width: 50px;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${ICON_DEFAULT_WIDTH}px;
  height: ${ICON_DEFAULT_HEIGHT}px;
  margin-bottom: 5px;
`;

const Title = styled.div`
  color: #333;
  font-weight: 500;
  height: 9px;
  white-space: nowrap;
  font-size: 10px;
  line-height: 9px;
  margin-top: 2px;
  text-align: center;
`;

const useGetNavItem = (): NavType[] => {
  const trans = useTranslation();
  const data = useMemo(() => {
    return [
      {
        key: "home",
        to: "/",
        activePath: ["/"],
        name: trans.t("home"),
        activeIcon: HomeSelIcon,
        defaultIcon: HomeNorIcon,
        width: ICON_DEFAULT_WIDTH,
      },
      {
        key: "search",
        to: getSearchPageUrl({ type: "intro" }),
        activePath: [
          "/searchV3/intro", // searchV3는 depcreated 되었습니다.
          "/searchV3/result", // searchV3는 depcreated 되었습니다.
          "/search/intro",
          "/search/result",
        ],
        name: trans.t("search"),
        activeIcon: SearchSelIcon,
        defaultIcon: SearchNorIcon,
        width: ICON_DEFAULT_WIDTH,
      },
      {
        key: "bookmark",
        to: "/bookmark",
        activePath: [
          "/bookmark",
          "/bookmark/post",
          "/bookmark/creator",
          "/bookmark/product",
          "/bookmark/like",
        ],
        name: trans.t("bookmark"),
        activeIcon: BookmarkSelIcon,
        defaultIcon: BookmarkNorIcon,
        width: ICON_DEFAULT_WIDTH,
      },
      {
        key: "my",
        to: "/mypage",
        activePath: [
          "/mypage",
          "/settings",
          "/user-mileage",
          "/partners/edit-creator-profile",
          "/myorders",
          "/mycoupons",
          "/partners/rewards",
          "/partners/rewards/history",
        ],
        name: trans.t("my"),
        activeIcon: MySelIcon,
        defaultIcon: MyNorIcon,
        width: ICON_DEFAULT_WIDTH,
      },
    ];
  }, [trans]);

  return data;
};

const BottomNav = () => {
  const location = useLocation();
  const { user, loading } = useAuth();
  const { vibrate } = useNativeEvent();
  const { track } = useAmplitude();
  // const { isOpen, openSheet, closeSheet } = useBottomSheet();
  const navigate = useNavigate();
  const { openSheet, isExposeAppDownload } = useAppDownLoad();
  const { isWeb } = useUserAgent();

  const pathname = location.pathname;
  const navItem = useGetNavItem();

  const handleClick = useCallback(
    ({ key, tabName, to }: { key: string; tabName: string; to: string }) => {
      vibrate();
      track("click bottom tab", {
        value: tabName,
        key,
      });

      const isClickedSameTab =
        pathname === to || (pathname !== "/" && to.includes(pathname));
      if (isClickedSameTab) {
        window.scrollTo({ behavior: "smooth", top: 0, left: 0 });
        return;
      }

      const isClickedStoreTab = isWeb && key === "store";
      if (isClickedStoreTab) {
        window.open("https://m.onthelook.store/", "_blank");
        return;
      }

      const isClickedTabNeededLogin =
        isExposeAppDownload &&
        (to === "/bookmark" || to === "/mypage") &&
        !user;
      if (isClickedTabNeededLogin) {
        openSheet();
        return;
      }

      navigate(to);
    },
    [
      vibrate,
      track,
      isWeb,
      isExposeAppDownload,
      user,
      pathname,
      openSheet,
      navigate,
    ]
  );

  if (loading) return null;

  return (
    <Footer>
      <Nav count={navItem.length}>
        {navItem.map((nav) => (
          <NavItem
            key={nav.name}
            onClick={() =>
              handleClick({ key: nav.key, tabName: nav.name, to: nav.to })
            }
          >
            <IconWrapper>
              <TabIcon
                src={
                  nav.activePath.includes(pathname)
                    ? nav.activeIcon
                    : nav.defaultIcon
                }
                width={nav.width}
                height={nav?.height}
                alt={nav.name}
              />
            </IconWrapper>
            <Title>{nav.name}</Title>
          </NavItem>
        ))}
      </Nav>
    </Footer>
  );
};

const BottomNavWrapper = (): JSX.Element | null => {
  const { isV2App } = useUserAgent();
  if (isV2App) return null;
  return <BottomNav />;
};

export default BottomNavWrapper;
