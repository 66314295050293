import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "./en.json";
import ko from "./ko.json";
import ja from "./ja.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    resources: {
      en: {
        translation: en,
      },
      ko: {
        translation: ko,
      },
      ja: {
        translation: ja,
      },
      jp: {
        translation: ja,
      },
    },
    ns: ["translation"],
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
