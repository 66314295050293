import * as Sentry from "@sentry/browser";

type AmplitudeAction = "setUserId" | "reset" | "identify" | "track";
type NativeAction =
  | "vibrate"
  | "request-review"
  | "shareLink"
  | "requestPushPermission";
type AuthAction = "login" | "logout";
export type NavigationAction =
  | "push"
  | "reset"
  | "goBack"
  | "replace"
  | "pop"
  | "popToTop"
  | "callback";

export type ScreenType =
  | "webview"
  | "native"
  | "global"
  | "modal"
  | "onthelook-store";

interface NavigationPayload {
  navigationAction: NavigationAction;
  path?: string;
  params?: { [index: string]: unknown };
  screenType: ScreenType;
}

interface PayloadTypeMap {
  AMPLITUDE: { method: AmplitudeAction; params?: { [index: string]: unknown } };
  NATIVE_EVENT:
    | { method: NativeAction; params?: { [index: string]: unknown } }
    | { method: "navigate"; params: NavigationPayload };
  PAGE_EVENT: { method: "hideBottomTab"; params: { visible: boolean } };
  AUTH: { method: AuthAction; params?: { token: string } };
  LANGUAGE: { method: "setLanguage"; params: { language: string } };
  FB_LOGIN:
    | { method: "login"; params: { permissions: string } }
    | { method: "logout" };
  USER_INFO: {
    method: "setUserInfo";
    params: { [index: string]: unknown };
  };
}

type MessageTypes = keyof PayloadTypeMap;

type InputTypeMap = {
  [K in MessageTypes]: K extends keyof PayloadTypeMap
    ? PayloadTypeMap[K]
    : never;
};

const sendMessageToNative = <K extends MessageTypes>(
  type: K,
  payload: InputTypeMap[K]
) => {
  const sendParams = {
    type,
    payload,
  };
  try {
    window?.ReactNativeWebView?.postMessage(JSON.stringify(sendParams));
  } catch (error) {
    Sentry?.captureException(error);
  }
};

export { sendMessageToNative };
