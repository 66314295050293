import React from "react";
import { BeatLoader } from "react-spinners";
import styled from "styled-components";

const Container = styled.div`
  width: 100wh;
  height: 100vh;
  display: flex;
  justify-content: center;
`;

const BarWrap = styled.div`
  margin: auto;
`;

const FlashLoadingForFullScreen = () => (
  <Container>
    <BarWrap>
      <BeatLoader size={10} color={"#111111"} loading={true} />
    </BarWrap>
  </Container>
);

export default FlashLoadingForFullScreen;
