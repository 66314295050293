import React, { createContext } from "react";

import useUserAgent from "core/user-agent/use-user-agent";

interface AppleSDKProviderProps {
  children: React.ReactNode;
}

const AppleSDKContext = createContext<Record<string, unknown> | undefined>(
  undefined
);

const initAppleSDK = (url: string) => {
  let scriptNode: HTMLScriptElement | null = document.querySelector(
    `script[src="${url}"]`
  );

  if (!scriptNode) {
    scriptNode = document.createElement("script");
    scriptNode.src = url;
    scriptNode.async = true;
    scriptNode.crossOrigin = "anonymous";
    scriptNode.onload = () => {
      if (window.AppleID) {
        window.AppleID.auth.init({
          clientId: "ios.onthelook.co.kr",
          redirectURI:
            `${import.meta.env.VITE_REST_API_ENDPOINT}` +
            "/apple-login-callback",
          scope: "name email",
          usePopup: false,
          responseMode: "form_post",
        });
      }
    };
    const head = document.querySelector("head");
    head?.appendChild(scriptNode);
  }
};

const AppleSDKProvider = ({ children }: AppleSDKProviderProps) => {
  const { isSearchEngineBot } = useUserAgent();

  if (!isSearchEngineBot) {
    initAppleSDK(
      "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
    );
  }

  return (
    <AppleSDKContext.Provider value={undefined}>
      {children}
    </AppleSDKContext.Provider>
  );
};

export default AppleSDKProvider;
