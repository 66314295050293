import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IconButton } from "@onthelook/ui";

import PostDetailProductCardSkeleton from "./post-detail-product-card-skeleton";

import { maxWidth } from "core/styles/mixin";
import Layout from "elements/layout";
import { Share } from "elements/icon";

const Container = styled.div`
  width: 100%;
  height: 100%;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;
  ${maxWidth}
`;

const GNB = styled.div`
  width: 100%;
  height: 56px;
  background-color: #fff;
`;

const PostHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  padding: 0 12px;

  & > a {
    display: flex;
    align-items: center;
  }
`;

const UserInfoWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UserThumbnail = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: #f4f4f4;
  margin-right: 8px;
`;

const UserName = styled.div`
  display: flex;
  align-items: center;
  width: 96px;
  font-size: 14px;
  font-weight: bold;
  color: #111;

  :empty {
    min-width: 80px;
    height: 16px;
    background-color: #f4f4f4;
    border-radius: 6px;
  }
`;

const FollowButton = styled.div`
  width: 58px;
  height: 32px;
  border-radius: 8px;
  background-color: #f4f4f4;
`;

const PostImageLayer = styled.div`
  min-height: ${window.innerWidth * 1.3}px;

  @media (min-width: 650px) {
    min-height: 650px;
  }

  background-color: #f4f4f4;
`;

const PostFooterLayer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px 18px 12px 20px;
`;

const LookInfomation = styled.div`
  font-size: 13px;
  color: #bbb;
  margin-bottom: 10px;
  margin-right: 26px;
  padding-top: 12px;
  display: flex;
  align-items: center;
`;

const LookInfoLeft = styled.div`
  display: flex;
  align-items: center;
`;

const BottomLayer = styled.div`
  margin-left: 24px;
`;

const PostDate = styled.div`
  height: 13px;
  width: 60px;
  margin-right: 24px;
  margin-bottom: 10px;
  background-color: #f4f4f4;
  border-radius: 6px;
`;

const PostLikeWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const PostDetailWrap = styled.div`
  display: flex;
  width: 100%;
  border-left: 2px solid #dcdcdc;
  padding: 0 10px;
`;

const InputArea = styled.input`
  width: 128px;
  height: 20px;
  border-radius: 6px;
  background-color: #f4f4f4;
  padding-left: 10px;

  ::placeholder {
    font-size: 13px;
    font-weight: 400;
  }
`;

const PostItemWrap = styled.div``;

const ProductSmallCardWrap = styled.div``;

// TODO: 각 컴포넌트 레벨에서 skeleton을 제공하도록 수정
const PostDetailSkeleton = ({ hideGnb }: { hideGnb?: boolean }) => {
  const trans = useTranslation();
  return (
    <Container>
      {!hideGnb && <GNB />}
      <PostHeader>
        <UserInfoWrap>
          <UserThumbnail />
          <UserName />
        </UserInfoWrap>
        <FollowButton />
      </PostHeader>
      <React.Fragment>
        <PostImageLayer />
        <PostFooterLayer>
          <PostLikeWrap>
            <IconButton
              name={"likePrimaryLineEnabled"}
              iconSize={28}
              aria-label="post-like"
              onClick={() => {}}
            />
            <IconButton
              iconSize={28}
              name="commentPrimaryLineEnabled"
              aria-label="comment"
            />
            <Share onClick={() => {}} />
          </PostLikeWrap>
          <IconButton
            name={"bookmarkPrimaryLineEnabled"}
            iconSize={28}
            aria-label="post-bookmark"
            onClick={() => {}}
          />
        </PostFooterLayer>
        <BottomLayer>
          <PostDate />
          <PostDetailWrap>
            <InputArea />
          </PostDetailWrap>
          <LookInfomation>
            <LookInfoLeft>{trans.t("wear_product")}</LookInfoLeft>
          </LookInfomation>
          <PostItemWrap>
            <ProductSmallCardWrap>
              <PostDetailProductCardSkeleton />
            </ProductSmallCardWrap>
            <ProductSmallCardWrap>
              <PostDetailProductCardSkeleton />
            </ProductSmallCardWrap>
          </PostItemWrap>
        </BottomLayer>
        <Layout.BottomNav />
      </React.Fragment>
    </Container>
  );
};

export default PostDetailSkeleton;
