import { toast } from "react-toastify";

export const toastBottom = (text: string) => {
  toast(text, {
    className: "otl_tostify bottom",
  });
};

export const toastTop = (text: string) => {
  toast(text, {
    className: "otl_tostify top",
    position: "top-center",
  });
};

export const toastTopError = (text: string) => {
  toast.error(text, {
    className: "otl_tostify error top",
    position: "top-center",
  });
};

export const toastBottomError = (text: string) => {
  toast.error(text, {
    className: "otl_tostify error bottom",
  });
};

export const toastTopInLongform = (text: string, id: number) => {
  toast(text, {
    className: "otl_tostify longform",
    position: "top-center",
    autoClose: 1000,
    toastId: id,
  });
};
