import { isEnglish, isJapan } from "./LanguageUtil";

import { toastBottom, toastTop } from "Functions/OtlToast";
import { getWebStorage } from "core/storage";

export const generateQueryString = (q) => {
  let queryString = "";
  if (q) {
    const queryKeys = Object.keys(q);
    queryKeys.forEach((key) => {
      if (q[key]) {
        if (q[key].toString().length) {
          queryString += key + "=" + q[key] + "&";
        }
      }
    });
    if (queryKeys.length > 0 && queryString[queryString.length - 1] === "&") {
      queryString = queryString.slice(0, -1);
    }
  }
  return queryString;
};

export const getCallbackUrl = (): string => {
  const storage = getWebStorage();
  const callbackUrl = storage.getItem("otlLoginCallbackUrl");
  return callbackUrl && typeof callbackUrl === "string"
    ? new URL(callbackUrl).pathname
    : "/";
};
type ScopeType = "profile" | "openid" | "email" | "profile openid email";

type GenerateLineLoginUrlType = (options: {
  redirectUri: string;
  state: string;
  scope?: ScopeType;
  disableAutoLogin?: boolean;
}) => string;

export const generateLineLoginUrl: GenerateLineLoginUrlType = ({
  redirectUri,
  state,
  scope = "profile openid email",
  disableAutoLogin = false,
}) => {
  const queryString = generateQueryString({
    client_id: import.meta.env.VITE_LINE_LOGIN_CLIENT_ID,
    redirect_uri: redirectUri,
    response_type: "code",
    scope,
    bot_prompt: "normal",
    ui_locales: "ja_JP",
    disable_auto_login: disableAutoLogin,
    state,
  });
  const url = `https://access.line.me/oauth2/v2.1/authorize?${queryString}`;
  return url;
};

export const generateAuthState = (): string => {
  return Math.random().toString(36).substring(2, 10);
};

/**
 * @deprecated 이 메소드는 더 이상 지원되지 않습니다. useAuth hook을 사용하세요.
 */
export const isLoggedIn = () => {
  return Boolean(localStorage.getItem("jwt"));
};

/**
 * @deprecated 이 메소드는 더 이상 지원되지 않습니다. Custom hook으로 변경될 예정입니다.
 */
export const redirectLoginPage = (
  args = { needToastTop: false, skipToast: false }
) => {
  let message = "로그인이 필요한 기능입니다.";

  if (isJapan) {
    message = "ログインが必要な機能です。";
  } else if (isEnglish) {
    message = "You need to login.";
  }

  if (!args.skipToast) {
    if (args.needToastTop) {
      toastTop(message);
    } else {
      toastBottom(message);
    }
  }

  setTimeout(() => {
    window.location.href = `/login?callbackUrl=${window.location.href}`;
  }, 700);
};
