export const DatetimeUtil = {
  datetimeForNow: (value) => {
    const today = new Date().getTime();

    const betweenTime = Math.floor((today - value) / 1000 / 60);
    if (betweenTime < 1) {
      return "방금";
    }
    if (betweenTime < 60) {
      return `${betweenTime}분`;
    }

    const betweenTimeHour = Math.floor(betweenTime / 60);
    if (betweenTimeHour < 24) {
      return `${betweenTimeHour}시간`;
    }

    const betweenTimeDay = Math.floor(betweenTime / 60 / 24);
    if (betweenTimeDay < 365) {
      return `${betweenTimeDay}일`;
    }

    return `${Math.floor(betweenTimeDay / 365)}년`;
  },
  getFormatDate: (timestamp) => {
    if (!timestamp) {
      return "";
    }
    const date = new Date(Number(timestamp));
    const year = date.getFullYear();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let month: any = 1 + date.getMonth();
    month = month >= 10 ? month : "0" + month;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let day: any = date.getDate();
    day = day >= 10 ? day : "0" + day;
    return String(String(year) + month) + day;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getFullFormatDate: (timestamp: any) => {
    if (!timestamp) {
      return "";
    }
    const date = new Date(Number(timestamp));
    const year = date.getFullYear();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let month: any = 1 + date.getMonth();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let day: any = date.getDate();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let hour: any = date.getHours();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let minute: any = date.getMinutes();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let seconds: any = date.getSeconds();

    month = month >= 10 ? month : "0" + month;
    day = day >= 10 ? day : "0" + day;
    hour = hour >= 10 ? hour : "0" + hour;
    minute = minute >= 10 ? minute : "0" + minute;
    seconds = seconds >= 10 ? seconds : "0" + seconds;

    return (
      year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + seconds
    );
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getKSTFormatDate: (timestamp: any) => {
    if (!timestamp) {
      return "";
    }
    // KST +09:00
    // const timediff = 9 * 60 * 60 * 1000;
    // const date = new Date(Number(timestamp) + timediff);
    const date = new Date(Number(timestamp));
    const year = date.getFullYear();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let month: any = 1 + date.getMonth();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let day: any = date.getDate();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let hour: any = date.getHours();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let minute: any = date.getMinutes();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let seconds: any = date.getSeconds();

    month = month >= 10 ? month : "0" + month;
    day = day >= 10 ? day : "0" + day;
    hour = hour >= 10 ? hour : "0" + hour;
    minute = minute >= 10 ? minute : "0" + minute;
    seconds = seconds >= 10 ? seconds : "0" + seconds;

    return (
      year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + seconds
    );
  },
};
