import React from "react";
import styled from "styled-components";

const ProductLayer = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: start;
  grid-template-columns: repeat(6, 1fr) 1.1fr;
  margin-bottom: 14px;
`;

const ProductImageWrap = styled.div`
  width: 64px;
  height: 64px;
  margin-right: 10px;
  position: relative;

  ::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    background-color: #f4f4f4;
    content: "";
  }
`;

const ProductImage = styled.div`
  width: 64px;
  height: 64px;
  object-fit: contain;
`;

const ProductInfo = styled.div`
  grid-column: 2 / 7;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
`;

const BrandNameWrap = styled.div`
  display: flex;
  align-items: center;
`;

const BrandName = styled.div`
  font-size: 13px;
  color: #111;
  font-weight: bold;

  :empty {
    width: 100px;
    height: 16px;
    background-color: #f4f4f4;
    border-radius: 6px;
  }
`;

const ProductName = styled.div`
  font-size: 13px;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 1px;

  :empty {
    width: 150px;
    height: 16px;
    background-color: #f4f4f4;
    border-radius: 6px;
  }
`;

const ProductPrice = styled.div`
  font-size: 13px;
  color: #999;
  display: flex;
  align-items: center;

  :empty {
    width: 100px;
    height: 16px;
    background-color: #f4f4f4;
    border-radius: 6px;
  }
`;

const Card = () => {
  return (
    <ProductLayer className={"horizontal"}>
      <ProductImageWrap className={"horizontal"}>
        <ProductImage />
      </ProductImageWrap>
      <ProductInfo className={"horizontal"}>
        <BrandNameWrap>
          <BrandName />
        </BrandNameWrap>
        <ProductName />
        <ProductPrice />
      </ProductInfo>
    </ProductLayer>
  );
};

const PostDetailProductCardSkeleton = () => {
  return <Card />;
};

export default PostDetailProductCardSkeleton;
